export const API_ENDPOINTS = {
  carePlan: "/api/patient/careplan/",
  patientProfile: "/api/patient/profile",
  updateProfileAttribute: "/api/patient/profile/attributes",
  statusMessages: "/api/patient/artifacts?artifactType=STATUSES",
  surveyResponses: "/api/patient/artifacts?artifactType=SURVEY_RESPONSES",
  tasks: "/api/patient/tasks",
  provisionedSurveys:
    "/api/patient/artifacts?artifactType=PROVISIONED_SURVEYES",
  infoPanel: "/api/patient/informationPanel",
  consentSubmission: (messageId, consentId) =>
    `/api/patient/messages/${messageId}/consents/${consentId}`
};
