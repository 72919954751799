import { http } from "../utils/http";
import { API_ENDPOINTS } from "../config";
import { store } from "../State/store/configureStore";
import { showToaster } from "../State/actions/toaster";
import { ROLE_MPS_ADMIN, ROLE_PATIENT } from "../security/roles";

class ContentLibraryService {
  static load(page, patientTypeId, topicId, onlyPublicContent) {
    let url = API_ENDPOINTS.loadContentLibrary();
    if (patientTypeId) {
      url += "&pTypeId=" + patientTypeId;
    }
    if (topicId) {
      url += "&topicId=" + topicId;
    }
    if (onlyPublicContent) {
      url += "&onlyPublicContent=true";
    }
    return http.request(url + `&page=0&size=500`).then(data => {
      let list = data && data._embedded ? data._embedded.libraries : [];
      return {
        list: list,
        loadMore: false,
        totalItems: data && data.page ? data.page.totalElements : 0
      };
    });
  }

  static add(data) {
    return http
      .request(API_ENDPOINTS.addLibraryContent(), "post", data)
      .then(data => {
        store.dispatch(showToaster("Library has been added.", "success", 5));
        return data;
      });
  }

  static loadTopics(patientTypeId) {
    let url = API_ENDPOINTS.loadTopics() + `?page=0&size=1000`;
    if (patientTypeId) {
      url += "&pTypeId=" + patientTypeId;
    }

    return http.request(url).then(data => {
      return data && data._embedded ? data._embedded.libraryTopics : [];
    });
  }

  static loadArticle(articleId) {
    let role = store.getState().user.role;
    let url = null;
    if (role === ROLE_PATIENT) {
      url = API_ENDPOINTS.loadArticlePatient(articleId);
    } else {
      url = API_ENDPOINTS.loadArticle(articleId);
    }
    return http.request(url);
  }

  static edit(contentId, data) {
    return http
      .request(API_ENDPOINTS.loadArticle(contentId), "patch", data)
      .then(result => {
        store.dispatch(showToaster("Library has been updated.", "success", 5));
      });
  }

  static addTopic(data) {
    return http.request(API_ENDPOINTS.addLibraryTopic(), "post", data);
  }

  static loadTopic(topicId) {
    return http.request(API_ENDPOINTS.loadTopic(topicId)).then(data => {
      return data._embedded ? data._embedded.libraryTopics : [];
    });
  }

  static editTopic(topicId, data) {
    return http.request(API_ENDPOINTS.loadTopic(topicId), "patch", data);
  }

  static deleteArticle(articleId) {
    return http.request(API_ENDPOINTS.loadArticle(articleId), "delete");
  }

  static loadLibraryItemForSignup(libId) {
    return http.request(API_ENDPOINTS.loadLibForSignUp(libId));
  }
}

const libraryService = ContentLibraryService;

export default libraryService;
