import { store } from "../State/store/configureStore";

export const FEATURES = {
  ADD_PATIENT: "AddPatient",
  PATIENT_COMMUNICATION: "PatientCommunication",
  PUSH_NOTIFICATION_FOR_STATUS_UPDATE: "PushNotificationForStatusUpdate",
  PATIENT_INVITE_MANAGEMENT: "PatientInviteManagement",
  SMS_NOTIFICATION_FOR_ONBOARDING: "SMSNotificationForOnBoarding",
  EMAIL_NOTIFICATION_FOR_ON_BOARDING: "EmailNotificationForOnBoarding",
  TASKS: "Tasks",
  PUSH_NOTIFICATION_FOR_URGENT_TASKS: "PushNotificationForUrgentTasks",
  PUSH_NOTIFICATION_FOR_ALL_TASKS: "PushNotificationForAllTasks",
  HARD_DELETE_ARTIFACTS: "HardDeleteArtifacts",
  CUSTOM_PATIENT_LISTING_ACTIONS: "CustomPatientListingActions",
  FETCH_DETAILED_REPORT: "FetchDetailedReport",
  FETCH_THERAPY_COMPLIANCE_REPORT: "FetchTherapyComplianceReport",
  PACKAGE_IMPORT_EXPORT: "Package_Import_Export",
  REMINDER_SERVICE: "ReminderService",
  EDIT_DELETE_STATUS_MESSAGE: "AllowEditDeleteStatusMessage",
  CUSTOM_PATIENT_REPORT: "CustomPatientReport",
  APPOINTMENT: "Appointment"
};

class FeaturesService {
  static canAddPatient() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ADD_PATIENT) !== -1;
  }

  static canTasks() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.TASKS) !== -1;
  }

  static canReminder() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.REMINDER_SERVICE) !== -1;
  }

  static canDoAppointments() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.APPOINTMENT) !== -1;
  }
}

export default FeaturesService;
