import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

export function validatePhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return false;
  }
  let valid = false;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
  } catch (e) {
    valid = false;
  }
  return valid;
}

export function doE164(countryCode, phone) {
  let formatted = null;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    formatted = phoneUtil.format(
      phoneUtil.parse(countryCode + phone),
      PhoneNumberFormat.E164
    );
  } catch (e) {
    formatted = null;
  }
  return formatted;
}

export function parsePhoneNumber(fullPhone) {
  if (!fullPhone) {
    return {};
  }
  let countryCode = null,
    phone = null;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    let number = phoneUtil.parse(fullPhone);
    countryCode = number.getCountryCode();
    phone = number.getNationalNumber();
  } catch (e) {
    countryCode = null;
    phone = null;
  }
  if (countryCode) {
    countryCode = "+" + countryCode;
  }
  return { countryCode, phone };
}

export function formatPhoneNumber(countryCode, phone) {
  let formatted = null;
  phone = countryCode ? countryCode + phone : phone;
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    formatted = phoneUtil.format(
      phoneUtil.parse(phone),
      PhoneNumberFormat.INTERNATIONAL
    );
  } catch (e) {
    formatted = phone;
  }
  return formatted;
}
