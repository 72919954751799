import { API_CLIENT_ID, API_CLIENT_SECRET, API_ENDPOINTS } from "../config";
import { API_URL_KEY, http } from "../utils/http";
import { Base64 } from "../utils/utils";
import { store } from "../State/store/configureStore";
import { isFacilityAdmin, isMpsAdmin, isStaff } from "../security/roles";
import staffService from "./staffService";
import { doE164 } from "../utils/phone";
import StorageService from "./storageService";
export const PATIENT_VIEW_TABS = "PATIENT_VIEW_TABS";

class UserService {
  logIn(username, password) {
    // console.log('service login', username, password);
    return new Promise((resolve, reject) => {
      const data = {
        grant_type: "password",
        client_id: API_CLIENT_ID,
        username: encodeURIComponent(username),
        password: password
      };

      // convert data object to form-data
      let str = Object.entries(data)
        .map(([key, val]) => `${key}=${val}`)
        .join("&");

      // console.log('PArams ', str);

      http
        .request(API_ENDPOINTS.get_token, "post", str, {
          Authorization:
            "Basic " + Base64.encode(API_CLIENT_ID + ":" + API_CLIENT_SECRET),
          "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
        })
        .then(data => {
          if (data.access_token) {
            http.setToken(data.access_token);
            resolve(data.access_token);
          } else {
            throw new Error("No access token granted");
          }
        })
        .catch(error => {
          // console.log('ERROR', error);
          reject(error.message ? error.message.toString() : null);
        });
    });
  }

  updateToken(token) {
    // console.log('UPDATE TOKEN');
    http.setToken(token);
    this.getSpaceInfo();
  }

  removeToken() {
    http.removeToken();
  }

  getSpaceInfo() {
    return http.request(API_ENDPOINTS.getSpaceInfo());
  }

  acceptInvite(inviteCode, email, phone, challengeFields) {
    let data = { inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    if (challengeFields && challengeFields.length > 0) {
      data.challengeFields = challengeFields;
    }
    data.acceptedTermsAndCondition = true;
    return http.request(API_ENDPOINTS.acceptInvite(), "post", data);
  }

  fetchSpaceDetailsFromInviteCode(inviteCode, email, phone) {
    let data = { inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    return http.request(
      API_ENDPOINTS.fetchSpaceDetailsFromInviteCode(),
      "post",
      data
    );
  }

  acceptTerms(inviteCode, email, phone) {
    let data = { inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    return http.request(API_ENDPOINTS.acceptTerms(), "post", data);
  }

  confirmCode(confirmationCode, inviteCode, email, phone) {
    let data = { smsCode: confirmationCode, inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    data.acceptedTermsAndCondition = true;

    return http.request(
      API_ENDPOINTS.confirmationCodeWithTerms(),
      "post",
      data
    );
  }

  setCredentials(loginUserId, password, inviteCode, email, phone) {
    let data = { loginUserId, password, inviteCode };
    if (email && email !== "") {
      data.email = email;
    } else if (phone && phone !== "") {
      data.phoneNumber = phone;
    }
    return http.request(API_ENDPOINTS.setCredentials(), "post", data);
  }

  processForgotPasswordRequest(data) {
    return http.request(API_ENDPOINTS.forgotPassword, "post", data);
  }

  resetPassword(data) {
    return http.request(API_ENDPOINTS.resetPassword, "post", data);
  }

  getAllSpaces() {
    return http.request(API_ENDPOINTS.allSpaces).then(data => {
      if (data && data._embedded && data._embedded.spaceDetails) {
        return data._embedded.spaceDetails || [];
      } else {
        return [];
      }
    });
  }

  changeSpace(newSpaceId) {
    return http.request(API_ENDPOINTS.changeSpace(newSpaceId), "post");
  }

  changePassword(password, newPassword) {
    return http.request(API_ENDPOINTS.changePassword, "post", {
      password,
      newPassword
    });
  }

  updateAvailabilityStatus = availabilityStatus => {
    return http.request(API_ENDPOINTS.loadStaffProfile, "patch", {
      availabilityStatus
    });
  };

  loadProfile() {
    let role = store.getState().user.role;
    if (isStaff(role)) {
      return staffService.loadProfile();
    } else if (isFacilityAdmin(role)) {
      return http.request(API_ENDPOINTS.loadFacilityAdminProfile);
    } else if (isMpsAdmin(role)) {
      return http.request(API_ENDPOINTS.loadMpsAdminProfile);
    }
  }

  editProfile(data) {
    let role = store.getState().user.role;
    if (isStaff(role)) {
      return staffService.editProfile(data);
    } else if (isFacilityAdmin(role)) {
      return http.request(
        API_ENDPOINTS.loadFacilityAdminProfile,
        "patch",
        data
      );
    } else if (isMpsAdmin(role)) {
      return http.request(API_ENDPOINTS.loadMpsAdminProfile, "patch", data);
    }
  }

  sendSmsEMailCampaigns(data) {
    return http.request(API_ENDPOINTS.campaigns, "post", data);
  }

  fetchChallengeFields(code) {
    return http.request(API_ENDPOINTS.challengeFields(code));
  }

  fetchChallengeFieldsFromFacilityCode(code) {
    return http.request(API_ENDPOINTS.challengeFieldsFromFacilityCode(code));
  }

  fetchAddPatientProfileFromCode(code) {
    return http.request(
      API_ENDPOINTS.fetchAddPatientProfileFromCode(code),
      "get",
      null,
      null,
      null,
      null,
      null,
      true
    );
  }

  onboardingConfirmIdentity(
    inviteCode,
    challengeFields,
    acceptedTermsAndCondition,
    onboardingType
  ) {
    let data = { acceptedTermsAndCondition };

    if (onboardingType === "signup") {
      data.facilityCode = inviteCode;
    } else {
      data.inviteCode = inviteCode;
    }

    if (challengeFields && challengeFields.length > 0) {
      let clonedChallengeFields = JSON.parse(JSON.stringify(challengeFields));
      for (let i = 0; i < clonedChallengeFields.length; i++) {
        let attribute = clonedChallengeFields[i];
        if (
          attribute.value &&
          (attribute.dataField === "PatientContactPhone" ||
            attribute.dataField === "PatientContactTel")
        ) {
          let newAV = JSON.parse(JSON.stringify(attribute.value));
          let phone = newAV.valueContactPoints[0].value;
          let cc = newAV.valueContactPoints[0].countryCode;
          delete newAV.valueContactPoints[0].countryCode;
          newAV.valueContactPoints[0].value = doE164(cc, phone);
          attribute.value = newAV;
        }

        // if (attribute.type === "DATE") {
        //   let av = attribute.value;
        //   if (av && av.valueDate) {
        //     av.valueDate = new Date(av.valueDate).getTime();
        //   }
        // }
      }
      data.challengeFields = clonedChallengeFields;
    }

    return http.request(API_ENDPOINTS.acceptInviteV1, "post", data);
  }

  onboardingSetCredentials(
    inviteCode,
    loginUserId,
    password,
    acceptedTermsAndCondition,
    onboardingType,
    patientId
  ) {
    let data = {
      loginUserId,
      password,
      acceptedTermsAndCondition
    };

    if (onboardingType === "signup") {
      data.patientId = patientId;
    } else {
      data.inviteCode = inviteCode;
    }

    return http.request(API_ENDPOINTS.setLoginCredentialsV1, "post", data);
  }

  getPatientViewTabs() {
    let tabs = StorageService.getFromLocalStorage(PATIENT_VIEW_TABS);
    if (!tabs) {
      tabs = [
        {
          id: "statuses",
          label: "STATUSES"
        },

        {
          id: "staff-notes",
          label: "STAFF NOTES"
        },

        {
          id: "care-plans",
          label: "CARE PLAN"
        },

        {
          id: "assessments",
          label: "ASSESSMENTS"
        },

        {
          id: "tasks",
          label: "TASKS"
        },

        {
          id: "usage",
          label: "USAGE"
        },

        {
          id: "audit-logs",
          label: "AUDIT LOGS"
        }
      ];
    }
    return tabs;
  }

  storePatientViewTabs(tabs) {
    StorageService.addToLocalStorage(PATIENT_VIEW_TABS, tabs);
  }

  signupPatient = data => {
    return http.request(API_ENDPOINTS.patientSignUp, "post", data);
  };

  confirmEmail = (confirmationCode, linkType) => {
    // emailConfirmation: code => `/api/signup/confirm/${code}`,
    let api =
      API_ENDPOINTS.emailConfirmation(confirmationCode) + "?cp=" + linkType;
    return http.request(api, "post");
  };

  validateSignUpField = data => {
    return http.request(API_ENDPOINTS.signUpValidation, "post", data);
  };

  fetchSignUpProfileFromCode(code) {
    return http.request(API_ENDPOINTS.signupProfileFromCode(code));
  }

  setServerURL(url) {
    http.setBaseURL(url);
    StorageService.addToLocalStorage(API_URL_KEY, url);
  }
}

const userService = new UserService();
export default userService;
