import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import Select from "react-select";

class StringObservation extends Component {
  static getDisplayValue(attributeValue, valuePlace) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      if (valuePlace === "from") {
        return attributeValue.valueObservation.value.valueFromString;
      } else if (valuePlace === "to") {
        return attributeValue.valueObservation.value.valueToString;
      }
      return attributeValue.valueObservation.value.valueString;
    }
  }

  static getOptionsList(options) {
    return options.map(option => {
      let val = option.valueObservation.value.valueString;
      return { value: val, label: val };
    });
  }

  render() {
    let {
      attributeValue,
      choiceList,
      options = [],
      setData,
      observationCode
    } = this.props;

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueString;
    attributeValue.valueObservation.code = observationCode;
    if (choiceList) {
      return (
        <FormGroup>
          <div className="inputHolder flex-row">
            <Select
              name="form-field-name"
              value={obsVal}
              onChange={option => {
                if (option) {
                  attributeValue.valueObservation.value.valueString =
                    option.value;
                } else {
                  attributeValue.valueObservation.value = {};
                }
                setData(attributeValue);
              }}
              options={options.map(option => {
                let val = option.valueObservation.value.valueString;
                return { value: val, label: val };
              })}
            />
          </div>
        </FormGroup>
      );
    } else {
      return (
        <FormGroup>
          <div className="inputHolder flex-row">
            <Input
              value={obsVal}
              onChange={e => {
                attributeValue.valueObservation.value.valueString =
                  e.target.value;
                setData(attributeValue);
              }}
              type="text"
            />
          </div>
        </FormGroup>
      );
    }
  }
}

export default StringObservation;
