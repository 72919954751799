import React from "react";
import { truncateStatusTitle } from "./index";

export function getStatusTitle(status) {
  let title = status.title;
  if (!title && status.detailMessage) {
    title = (
      <div
        dangerouslySetInnerHTML={{
          __html: truncateStatusTitle(status.detailMessage)
        }}
      />
    );
  }
  if (!title && status.textMessage) {
    title = truncateStatusTitle(status.textMessage);
  }

  if (!title && status.artifactLibrary && status.artifactLibrary.title) {
    title = truncateStatusTitle(status.artifactLibrary.title);
  }

  return title;
}