import { Alert } from "reactstrap";
import React, { Component } from "react";
import { connect } from "react-redux";
import { closeToaster } from "../../State/actions/toaster";

class Toaster extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.processMessage(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.processMessage(nextProps);
  }

  processMessage = props => {
    let { message, type, duration = 5 } = props;
    if (message) {
      setTimeout(() => {
        this.setState({ show: false });
        this.props.closeToaster();
      }, duration * 1000);

      this.setState({ message, type, show: true });
    }
  };

  closeToaster = () => {
    this.setState({
      message: null,
      type: null,
      duration: 0,
      show: false
    });
  };

  render() {
    let { message, type, show } = this.state;

    let color = type || "info";
    if (color === "error") {
      color = "danger";
    }

    return (
      <div
        style={{
          position: "fixed",
          // top: "60px",
          // right: "10px",
          zIndex: 9999,
          // minWidth: "300px",

          top: "5px",
          marginLeft: "-175px",
          left: "50%",
          width: "350px"
        }}
      >
        {show && (
          <Alert
            style={{ padding: "0.75rem" }}
            color={color}
            toggle={this.closeToaster}
            closeClassName="toaster-close-btn"
          >
            {message}
          </Alert>
        )}
      </div>
    );
  }
}

function propsMapper(state, props) {
  return {
    message: state.toaster.message,
    type: state.toaster.type,
    duration: state.toaster.duration
  };
}
const actionsMapper = {
  closeToaster: closeToaster
};

export default connect(
  propsMapper,
  actionsMapper
)(Toaster);
