import React, { PureComponent } from "react";
import UserOnboardingChallengeInvite, {
  getOnboardingAttributeValue
} from "../UserOnboarding/UserOnboardingChallengeInvite";
import { Button, Col, Row } from "reactstrap";
import { getValueWhenInFilter } from "../../Shared/Packages/UiAttributes/Utils";
import SignUpCredentialsForm from "./SignUpCredentialsForm";
import MPSBarLoader from "../../components/MPSBarLoader";
import { getSystemField } from "../../Shared/Packages/UiAttributes/Elements/ContactPoint";
import { doE164 } from "../../utils/phone";

class SignupForm extends PureComponent {
  constructor(props) {
    super(props);
    this.attributesRef = {};
    // let sections  = this.props.sections||[];
    // sections = JSON.parse(JSON.stringify(sections))
    this.state = { sections: this.preProcessSections(this.props.sections) };
  }

  preProcessSections = (sections = []) => {
    sections = [...sections];
    let emailIndex = { section: -1, item: -1 };
    let phoneIndex = { section: -1, item: -1 };
    for (let i = 0; i < sections.length; i++) {
      let items = sections[i].items;
      for (let j = 0; j < items.length; j++) {
        let attribute = items[j].attribute;
        let { dataField, value } = attribute;

        if (attribute.dataField === "PatientContactEmail") {
          emailIndex.section = i;
          emailIndex.item = j + 1;
        }
        if (
          dataField === "PatientContactPhone" ||
          dataField === "PatientContactTel"
        ) {
          if (dataField === "PatientContactPhone") {
            phoneIndex.section = i;
            phoneIndex.item = j + 1;
          }
          value.valueContactPoints = value.valueContactPoints || [];
          value.valueContactPoints[0] = value.valueContactPoints[0] || {
            system: getSystemField(dataField),
            use: "WORK"
          };
          let countryCode = value.valueContactPoints[0].countryCode;
          value.valueContactPoints[0].countryCode = countryCode || "+353";
        }

        if (attribute.attributeId === "confirm-email") {
          emailIndex = { section: -1, item: -1 };
        }
        if (attribute.attributeId === "confirm-phone") {
          phoneIndex = { section: -1, item: -1 };
        }
      }
    }

    if (emailIndex.section !== -1 && emailIndex.item !== -1) {
      let emailItems = sections[emailIndex.section].items;
      emailItems.splice(emailIndex.item, 0, {
        attribute: {
          attributeId: "confirm-email",
          attributeType: "CONTACT_POINT",
          dataField: "PatientContactEmail",
          hidden: true,
          name: "ConfirmEmail",
          title: "Confirm Email",
          value: {},
          error: "Confirm Email is required"
        },
        name: "ConfirmEmail",
        title: "Confirm Email",
        type: "ATTRIBUTE"
      });
      if (phoneIndex.section !== -1 && phoneIndex.item !== -1) {
        phoneIndex.item++;
      }
    }
    if (phoneIndex.section !== -1 && phoneIndex.item !== -1) {
      let phoneItems = sections[phoneIndex.section].items;
      phoneItems.splice(phoneIndex.item, 0, {
        attribute: {
          attributeId: "confirm-phone",
          attributeType: "CONTACT_POINT",
          dataField: "PatientContactPhone",
          hidden: true,
          name: "ConfirmPhone",
          title: "Confirm Mobile Ph. #",
          value: {},
          error: "Confirm Phone is required"
        },
        name: "ConfirmPhone",
        title: "Confirm Mobile Ph. #",
        type: "ATTRIBUTE"
      });
    }
    return sections;
  };

  onUpdateData = (sectionIndex, name, attributes) => {
    let { sections } = this.state;
    let section = sections[sectionIndex];
    let items = section.items || [];
    items.forEach(item => {
      let attr = attributes.filter(att => att.name === item.attribute.name)[0];
      if (attr.focus) {
        attr.focus = false;
      }
      item.attribute = {
        ...attr
      };
    });
    section.items = [...items];
    this.setState({ sections: [...sections] });
  };

  validate = existingLoginUserId => {
    this.setState({ phoneEmailError: false });
    let sections = this.state.sections || [];
    sections = [...sections];

    let eitherEmailOrPhone = false;
    let pageHasEmailOrPhone = false;
    let formValid = true;
    let firstError = null;
    for (let a = 0; a < sections.length; a++) {
      let section = { ...sections[a] };
      let fields = section.items || [];
      for (let i = 0; i < fields.length; i++) {
        let field = { ...fields[i] };
        let attribute = { ...field.attribute };
        let value = getOnboardingAttributeValue(attribute);
        if (!attribute.hidden) {
          if (attribute.error) {
            formValid = false;
          } else {
            if (attribute.mandatory && !value) {
              attribute.error = attribute.title + " is required.";
              formValid = false;
            }
          }
        }

        if (
          attribute.dataField === "PatientContactEmail" ||
          attribute.dataField === "PatientContactPhone"
        ) {
          pageHasEmailOrPhone = true;
          if (value) {
            eitherEmailOrPhone = true;
          }
        }

        if (!firstError) {
          firstError = attribute;
        }

        field.attribute = attribute;
        fields[i] = field;
      }
      section.fields = [...fields];
    }
    let phoneEmailError = pageHasEmailOrPhone && !eitherEmailOrPhone;
    this.setState({ sections: [...sections], phoneEmailError });

    let credFormValid = true;
    if (!existingLoginUserId) {
      credFormValid = this.credForm.validate();
    }

    if (!formValid && firstError) {
      window.scrollTo(0, this.attributesRef[firstError.name].offsetTop);
    }

    return formValid && !phoneEmailError && credFormValid;
  };

  isConfirmationField = attribute => {
    return (
      attribute.attributeId === "confirm-email" ||
      attribute.attributeId === "confirm-phone"
    );
  };

  submit = existingLoginUserId => {
    this.setState({ errorTerms: false });
    let valid = this.validate(existingLoginUserId);
    if (!valid) {
      return;
    }

    let sections = this.state.sections || [];
    sections = JSON.parse(JSON.stringify(sections));

    let attributes = [];
    sections.forEach(section => {
      let challengeFields = section.items || [];
      challengeFields.forEach(cf => {
        let attribute = cf.attribute;
        if (!this.isConfirmationField(attribute)) {
          let value = getOnboardingAttributeValue(attribute);
          if (value) {
            // if (attribute.attributeType === "DATE") {
            //   let av = attribute.value;
            //   if (av && av.valueDate) {
            //     av.valueDate = new Date(av.valueDate).getTime();
            //   }
            // }
            if (attribute.dataField === "PatientContactPhone") {
              let av = attribute.value;
              let phone = av.valueContactPoints[0].value;
              let cc = av.valueContactPoints[0].countryCode;
              delete av.valueContactPoints[0].countryCode;
              av.valueContactPoints[0].value = doE164(cc, phone);
              attribute.value = av;
            }
            attributes.push({ ...attribute });
          }
        }
      });
    });

    let finalData = {
      attributes
    };
    if (!existingLoginUserId) {
      finalData.loginUserId = this.state.username;
      finalData.password = this.state.password;
    }
    this.props.submit(finalData);
  };

  setCredentialsData = (name, value) => {
    this.setState({ [name]: value });
  };

  hasExistingLoginUserId = () => {
    let existingLoginUserId = false;
    let attributes = {};
    let { sections } = this.state;
    for (let i = 0; i < sections.length; i++) {
      let items = sections[i].items;
      for (let j = 0; j < items.length; j++) {
        let attribute = items[j].attribute;
        if (attribute.validationCode === "USER_HAS_USERNAME") {
          if (attribute.dataField === "PatientContactEmail") {
            attributes["email"] = true;
          }
          if (attribute.dataField === "PatientContactPhone") {
            attributes["phone"] = true;
          }
          existingLoginUserId = true;
        }
      }
    }
    return { existingLoginUserId, loginUserIdAttrs: attributes };
  };

  setCredentialPresent = (present, loginUserId) => {
    if (present) {
      this.setState({ credentialsPresent: present, loginUserId });
    } else {
      this.setState({ credentialsPresent: false, loginUserId: null });
    }
  };

  onBlurForConfirmFields = (attribute, itemIndex, sectionIndex, field) => {
    let value = getValueWhenInFilter(attribute);
    let { sections } = this.props;
    let section = sections[sectionIndex];
    let confirmAttribute = section.items[itemIndex + 1].attribute;
    let state = {};
    if (value) {
      confirmAttribute = {
        ...confirmAttribute,
        hidden: false,
        mandatory: true,
        focus: true
      };
      state.phoneEmailError = false;
    } else {
      confirmAttribute = {
        ...confirmAttribute,
        hidden: true,
        mandatory: false,
        focus: false
      };
    }
    section.items[itemIndex + 1].attribute = confirmAttribute;
    state.sections = [...sections];
    if (field === "EMAIL") {
      if (!this.state.username) {
        state.username = value;
      }
      if (this.credForm) this.credForm.state.username = value;
    }
    this.setState(state);
  };

  setAttributeRef = (name, ref) => {
    this.attributesRef[name] = ref;
  };

  render() {
    let {
      sections,
      username,
      password,
      confirmPassword,
      phoneEmailError
    } = this.state;

    let { confirmationError } = this.props;
    let {
      existingLoginUserId,
      loginUserIdAttrs
    } = this.hasExistingLoginUserId();

    return (
      <div>
        {sections.map((section, index) => {
          let challengeFields = [];
          if (section && section.items && section.items.length > 0) {
            challengeFields = section.items;
          }
          return (
            <Row className="justify-content-center mb-2" key={index}>
              <Col md={{ size: 8 }}>
                <div className="mb-3">
                  <h6 style={{ marginBottom: 0 }}>{section.title}</h6>
                  <small>{section.subTitle}</small>
                </div>

                <UserOnboardingChallengeInvite
                  sectionIndex={index}
                  facilityId={this.props.facilityId}
                  onUpdateData={(name, attributes) => {
                    this.onUpdateData(index, name, attributes);
                  }}
                  referrer="SELF_SIGNUP"
                  challengeFields={challengeFields.map(cf => {
                    if (cf.mandatory) {
                      cf.attribute.mandatory = true;
                    }
                    return cf.attribute;
                  })}
                  setCredentialPresent={this.setCredentialPresent}
                  onBlurForConfirmFields={this.onBlurForConfirmFields}
                  facilityCode={this.props.facilityCode}
                  setAttributeRef={this.setAttributeRef}
                />
              </Col>
            </Row>
          );
        })}

        <Row className="justify-content-center mb-2">
          <Col md={{ size: 8 }}>
            <div className="mb-3">
              <h6 style={{ marginBottom: 0 }}>Login Credentials</h6>
            </div>

            <SignUpCredentialsForm
              ref={credForm => {
                this.credForm = credForm;
              }}
              formErrors={this.state.credFormErrors}
              setCredentialsData={this.setCredentialsData}
              username={username}
              password={password}
              confirmPassword={confirmPassword}
              existingLoginUserId={existingLoginUserId}
              loginUserIdAttrs={loginUserIdAttrs}
              facilityCode={this.props.facilityCode}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs="12">
            {phoneEmailError && (
              <p className="text-muted text-center">
                <span style={{ color: "red" }}>
                  Please enter email or mobile phone number.
                </span>
              </p>
            )}
            {confirmationError && (
              <p className="text-muted text-center">
                <span style={{ color: "red" }}>{confirmationError}</span>
              </p>
            )}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs="12">
            <div className="d-flex justify-content-between">
              <div>
                <Button
                  color="secondary"
                  className="px-4"
                  block
                  onClick={this.props.decrementStep}
                >
                  Back
                </Button>
              </div>

              <div className="d-flex align-items-center">
                {!this.props.submitting && (
                  <Button
                    size="lg"
                    color="primary"
                    className="px-4"
                    onClick={() => {
                      this.submit(existingLoginUserId);
                    }}
                  >
                    Submit
                  </Button>
                )}
                {this.props.submitting && (
                  <div className="d-flex" style={{ width: 50 }}>
                    <MPSBarLoader />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupForm;
