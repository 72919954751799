import { API_ENDPOINTS } from "../config";
import { http } from "../utils/http";
import { store } from "../State/store/configureStore";
import { showProgress } from "../State/actions/uploadProgress";

class MasterDataService {
  static loadData(patientTypeId) {
    let api = API_ENDPOINTS.masterData + "?page=0&size=1000";
    if (patientTypeId) {
      api += "&pTypeId=" + patientTypeId;
    }
    return http.request(api).then(data => {
      return data && data._embedded ? data._embedded.masterDatas : [];
    });
  }
  static addNewData(data) {
    return http.request(API_ENDPOINTS.masterData, "post", data);
  }

  static editMasterData(data, id) {
    return http.request(API_ENDPOINTS.masterDataEdit(id), "patch", data);
  }

  static deleteMasterData(dataId) {
    return http.request(API_ENDPOINTS.masterDataEdit(dataId), "delete");
  }

  static uploadCSV = (patientTypeId, masterDataName, csvFile) => {
    const formData = new FormData();
    formData.append("pTypeId", patientTypeId);
    formData.append("name", masterDataName);
    formData.append("files", csvFile);
    return http.request(
      API_ENDPOINTS.masterDataCSVUpload,
      "post",
      formData,
      null,
      progressEvent => {
        const uploadProgress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        store.dispatch(showProgress(uploadProgress));
      },
      120000
    );
  };
}

const masterDataService = MasterDataService;

export default masterDataService;
