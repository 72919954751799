// @flow

import {
  DISMISS_ERROR,
  GET_SPACE_DETAILS,
  GET_SPACE_DETAILS_DONE,
  GET_SPACE_DETAILS_ERROR,
  INVITE_PATIENT_ADMIN_USER,
  INVITE_PATIENT_ADMIN_USER_DONE,
  INVITE_PATIENT_ADMIN_USER_ERROR,
  INVITE_PATIENT_SPACE_MEMBERS,
  INVITE_PATIENT_SPACE_MEMBERS_DONE,
  INVITE_PATIENT_SPACE_MEMBERS_ERROR,
  LOAD_MEMBERS,
  LOAD_PATIENT,
  LOAD_PATIENTS,
  LOAD_PATIENT_DONE,
  LOG_OUT,
  MEMBERS_LOADED,
  PATIENTS_LOADED,
  PATIENTS_MEMBERS_RESET_LOADING,
  PATIENTS_RESET_LOADING,
  PATIENT_ADD,
  PATIENT_ADD_DONE,
  PATIENT_ADD_ERROR,
  PATIENT_DELETE_DONE,
  PATIENT_DELETE_ERROR,
  PATIENT_UPDATE,
  PATIENT_UPDATE_DONE
} from "../actions/actionTypes";
import { REHYDRATE } from "redux-persist";
import { findWithAttr } from "../../utils/utils";

const initialState = {
  error: false,
  loading: false,
  // last page loaded
  page: 0,
  // is there need to load more, currently determined
  // to be false if last page listing returned no results
  loadMore: true,
  list: [],
  // list of members of patient space
  membersLoading: false,
  membersList: [],
  membersLoadMore: true,
  membersPage: 0,
  // success for invite admin/members and add patient
  addSuccess: false,
  inviteSuccess: false
};

const patientsReducer = (state = initialState, action) => {
  let list, index;
  switch (action.type) {
    case PATIENTS_RESET_LOADING:
      return initialState;
    case PATIENTS_MEMBERS_RESET_LOADING:
      return {
        ...state,
        membersLoading: false,
        membersList: [],
        membersLoadMore: true,
        membersPage: 0
      };

    case PATIENT_UPDATE:
    case PATIENT_ADD:
    case LOAD_PATIENT:
    case INVITE_PATIENT_ADMIN_USER:
    case INVITE_PATIENT_SPACE_MEMBERS:
    case GET_SPACE_DETAILS:
    case LOAD_PATIENTS:
      return { ...state, loading: true };
    case LOAD_MEMBERS:
      return { ...state, membersLoading: true, loading: false };

    case DISMISS_ERROR:
      return { ...state, error: false, errorMsg: "" };

    case PATIENT_ADD_ERROR:
    case PATIENT_DELETE_ERROR:
    case INVITE_PATIENT_ADMIN_USER_ERROR:
    case INVITE_PATIENT_SPACE_MEMBERS_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case INVITE_PATIENT_ADMIN_USER_DONE:
      return { ...state, loading: false, addSuccess: true };

    case INVITE_PATIENT_SPACE_MEMBERS_DONE:
      return { ...state, loading: false, addSuccess: true };

    case GET_SPACE_DETAILS_DONE:
      // console.log('reducer GET SPACEDETAILS', action);
      return { ...state, spaceDetails: action.spaceDetails };

    case GET_SPACE_DETAILS_ERROR:
      return { ...state, error: true };

    case LOAD_PATIENT_DONE:
    case PATIENT_UPDATE_DONE:
    case PATIENT_ADD_DONE:
      // console.log('PATIENT ', action);
      list = state.list.slice(0);

      const patientId = action.data.patientId || -1;
      index = findWithAttr(list, "patientId", patientId);

      // not found then return prev state
      if (index === -1)
        return {
          ...state,
          loading: false,
          list: [action.data].concat(list),
          addPatientId: patientId,
          addSuccess:
            action.type === PATIENT_ADD_DONE ||
            action.type === PATIENT_UPDATE_DONE
        };

      // if found, replace whole object
      list[index] = action.data;

      return {
        ...state,
        loading: false,
        list: list,
        addPatientId: patientId,
        addSuccess:
          action.type === PATIENT_ADD_DONE ||
          action.type === PATIENT_UPDATE_DONE
      };

    case PATIENT_DELETE_DONE:
      // console.log('PATIENT ', action);
      list = state.list.slice(0);

      index = findWithAttr(list, "patientId", action.patientId);

      // not found then return prev state
      if (index === -1) return state;

      // if found, replace whole object
      list.splice(index, 1);
      return { ...state, list };

    // case PATIENT_UPDATE_DONE:
    //   list = state.list.slice(0);
    //   return { ...state, loading: false, list };

    case MEMBERS_LOADED:
      // console.log('patients loaded', action);
      return {
        ...state,
        membersLoading: false,
        membersPage: action.membersPage,
        membersLoadMore: action.membersLoadMore,
        membersList:
          action.page === 0
            ? action.membersList
            : state.membersList.slice(0).concat(action.membersList)
      };

    case PATIENTS_LOADED:
      // console.log('patients loaded', action);
      return {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        total: action.total,
        list:
          action.page === 0
            ? action.list
            : state.list.slice(0).concat(action.list)
      };

    case LOG_OUT:
      return initialState;

    case REHYDRATE:
      if (action.payload && action.payload.patients) {
        return { ...state, ...action.payload.patients, loading: false };
      }
      return state;

    default:
      return state;
  }
};

export default patientsReducer;
