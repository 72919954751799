import React, { Component } from "react";
import PreviewWithMedia from "./PreviewWithMedia";
import PreviewWithExternalContent from "./PreviewWithExternalContent";
import MPSButton from "../../../components/Buttons/Buttons/MPSButton";

class ArticlePreview extends Component {
  render() {
    let article = this.props.article;
    if (!article) {
      return null;
    }

    let { embed, mps, mpsPackageId } = this.props;

    let type = article.type;
    let url = "/content-library/" + article.id + "/edit";
    if (mps) {
      url =
        "/packages/" +
        mpsPackageId +
        "/content-library/" +
        article.id +
        "/edit";
    }

    return (
      <div>
        <div>
          {!embed && <h2>Content Library - {article.title}</h2>}
          {embed && <h4>{article.title}</h4>}

          {!embed && (
            <MPSButton
              color="primary"
              className="btn-add"
              onClick={() => {
                this.props.history.push(url);
              }}
            >
              Edit
            </MPSButton>
          )}
        </div>
        <div>
          {!embed && <h3>Preview</h3>}

          {type === "CONTENT_LIBRARY" && <PreviewWithMedia article={article} />}
          {type === "NO_CONTENT_LIBRARY" && (
            <div>
              {article.detailText && (
                <div dangerouslySetInnerHTML={{ __html: article.detailText }} />
              )}
            </div>
          )}
          {type === "EXTERNAL_URL" && (
            <PreviewWithExternalContent article={article} />
          )}
        </div>

        {article.libraryRenditions && article.libraryRenditions.length > 0 && (
          <div>
            <span>Renditions:</span>
            <div className="inputHolder">
              {article.libraryRenditions.map(rendition => (
                <div>{rendition.format + " | " + rendition.fileName}</div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ArticlePreview;
