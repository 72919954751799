export const DISMISS_ERROR = "DISMISS_ERROR";

export const DUMMY_ACTION = "DUMMY_ACTION";

export const RESET_LOADING_USER = "RESET_LOADING_USER";

// happens when user edits facility as facility admin
export const UPDATE_SPACE_INFO = "UPDATE_SPACE_INFO";

export const LOG_IN = "LOG_IN";
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const SET_FACILITY_DETAILS = "SET_FACILITY_DETAILS";
export const FETCH_FACILITY_DETAILS = "FETCH_FACILITY_DETAILS";
export const LOG_IN_ERROR = "LOG_IN_ERROR";
export const LOG_OUT = "LOG_OUT";
export const ACCEPT_INVITE = "ACCEPT_INVITE";
export const ACCEPT_INVITE_DONE = "ACCEPT_INVITE_DONE";
export const ACCEPT_INVITE_ERROR = "ACCEPT_INVITE_ERROR";
export const ACCEPT_TERMS = "ACCEPT_TERMS";
export const ACCEPT_TERMS_DONE = "ACCEPT_TERMS_DONE";
export const ACCEPT_TERMS_ERROR = "ACCEPT_TERMS_ERROR";
export const CONFIRMATION_CODE = "CONFIRMATION_CODE";
export const CONFIRMATION_CODE_DONE = "CONFIRMATION_CODE_DONE";
export const CONFIRMATION_CODE_ERROR = "CONFIRMATION_CODE_ERROR";
export const SET_CREDENTIALS = "SET_CREDENTIALS";
export const SET_CREDENTIALS_DONE = "SET_CREDENTIALS_DONE";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_CREDENTIALS_ERROR = "SET_CREDENTIALS_ERROR";
export const UPDATE_AVAILABILITY_STATUS = "UPDATE_AVAILABILITY_STATUS";
// export const SWITCH_USER_TYPE = 'SWITCH_USER_TYPE';
export const CHANGE_SPACE = "CHANGE_SPACE";

export const LOAD_ORGANIZATIONS = "LOAD_ORGANIZATIONS";
export const ORGANIZATIONS_LOADED = "ORGANIZATIONS_LOADED";

export const ORGANIZATION_ADMINS_RESET_LOADING =
  "ORGANIZATION_ADMINS_RESET_LOADING";
export const LOAD_ORGANIZATION_ADMINS = "LOAD_ORGANIZATION_ADMINS";
export const LOAD_ORGANIZATION_ADMINS_DONE = "LOAD_ORGANIZATION_ADMINS_DONE";
// export const LOAD_ORGANIZATION_ADMIN = 'LOAD_ORGANIZATION_ADMIN';
// export const LOAD_ORGANIZATION_ADMIN_DONE = 'LOAD_ORGANIZATION_ADMIN_DONE';
export const ORGANIZATION_ADMIN_INVITE = "ORGANIZATION_ADMIN_INVITE";
export const ORGANIZATION_ADMIN_INVITE_DONE = "ORGANIZATION_ADMIN_INVITE_DONE";
export const ORGANIZATION_ADMIN_INVITE_ERROR =
  "ORGANIZATION_ADMIN_INVITE_ERROR";

export const ORGANIZATION_ADMIN_DELETE = "ORGANIZATION_ADMIN_DELETE";
export const ORGANIZATION_ADMIN_DELETE_DONE = "ORGANIZATION_ADMIN_DELETE_DONE";
export const ORGANIZATION_ADMIN_DELETE_ERROR =
  "ORGANIZATION_ADMIN_DELETE_ERROR";

export const LOAD_ORGANIZATION = "LOAD_ORGANIZATION";
export const LOAD_ORGANIZATION_DONE = "LOAD_ORGANIZATION_DONE";
export const ORGANIZATIONS_RESET_LOADING = "ORGANIZATIONS_RESET_LOADING";
export const ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE";
export const ORGANIZATION_EDIT = "ORGANIZATION_EDIT";
export const ORGANIZATION_UPDATE_DONE = "ORGANIZATION_UPDATE_DONE";
export const ORGANIZATION_ADD = "ORGANIZATION_ADD";
export const ORGANIZATION_ADD_DONE = "ORGANIZATION_ADD_DONE";
export const ORGANIZATION_UPDATE_BRANDING = "ORGANIZATION_UPDATE_BRANDING";
export const ORGANIZATION_UPDATE_BRANDING_DONE =
  "ORGANIZATION_UPDATE_BRANDING_DONE";

export const LOAD_PATIENTS = "LOAD_PATIENTS";
export const PATIENTS_LOADED = "PATIENTS_LOADED";

export const LOAD_MEMBERS = "LOAD_MEMBERS";
export const MEMBERS_LOADED = "MEMBERS_LOADED";

export const LOAD_PATIENT = "LOAD_PATIENT";
export const LOAD_PATIENT_DONE = "LOAD_PATIENT_DONE";
export const PATIENTS_RESET_LOADING = "PATIENTS_RESET_LOADING";
export const PATIENTS_MEMBERS_RESET_LOADING = "PATIENTS_MEMBERS_RESET_LOADING";
export const PATIENT_UPDATE = "PATIENT_UPDATE";
export const PATIENT_UPDATE_DONE = "PATIENT_UPDATE_DONE";
export const PATIENT_ADD = "PATIENT_ADD";
export const PATIENT_ADD_DONE = "PATIENT_ADD_DONE";
export const PATIENT_ADD_ERROR = "PATIENT_ADD_ERROR";
export const PATIENT_DELETE = "PATIENT_DELETE";
export const PATIENT_DELETE_DONE = "PATIENT_DELETE_DONE";
export const PATIENT_DELETE_ERROR = "PATIENT_DELETE_ERROR";

export const PATIENT_TYPES_RESET_LOADING = "PATIENT_TYPES_RESET_LOADING";
export const PATIENT_TYPES_LOAD = "PATIENT_TYPES_LOAD";
export const PATIENT_TYPES_LOAD_DONE = "PATIENT_TYPES_LOAD_DONE";
export const PATIENT_TYPES_LOAD_ERROR = "PATIENT_TYPES_LOAD_ERROR";
export const SET_PATIENT_TYPE_ID = "SET_PATIENT_TYPE_ID";

export const LOAD_STATUSES = "LOAD_STATUSES";
export const STATUSES_LOADED = "STATUSES_LOADED";
export const STAFF_NOTES_LOADED = "STAFF_NOTES_LOADED";
export const STATUS_ADD = "STATUS_ADD";
export const STATUS_ADD_DONE = "STATUS_ADD_DONE";
export const STATUS_ADD_FROM_SURVEY = "STATUS_ADD_FROM_SURVEY";
export const ADD_LIBRARY_STATUS = "ADD_LIBRARY_STATUS";
export const ADD_CONSENT_STATUS = "ADD_CONSENT_STATUS";
export const ADD_LIBRARY_STATUS_DONE = "ADD_LIBRARY_STATUS_DONE";
export const ADD_CONSENT_STATUS_DONE = "ADD_CONSENT_STATUS_DONE";
export const STATUS_ADD_FROM_SURVEY_DONE = "STATUS_ADD_FROM_SURVEY_DONE";
export const STATUS_RESET_LOADING = "STATUS_RESET_LOADING";

export const LOAD_STATUS_TEMPLATES = "LOAD_STATUS_TEMPLATES";
export const STATUS_TEMPLATES_LOADED = "STATUS_TEMPLATES_LOADED";
export const STATUS_TEMPLATE_ADD = "STATUS_TEMPLATE_ADD";
export const STATUS_TEMPLATE_ADD_DONE = "STATUS_TEMPLATE_ADD_DONE";
export const STATUS_TEMPLATE_ADD_ERROR = "STATUS_TEMPLATE_ADD_ERROR";

export const LOAD_SURVEYS = "LOAD_SURVEYS";
export const SURVEYS_LOADED = "SURVEYS_LOADED";
export const SURVEYS_RESET_LOADING = "SURVEY_RESET_LOADING";

export const SURVEY_ADD = "SURVEY_ADD";
export const SURVEY_ADD_DONE = "SURVEY_ADD_DONE";
export const SURVEY_ADD_ERROR = "SURVEY_ADD_ERROR";

export const UPDATE_CREATE_SURVEY = "UPDATE_CREATE_SURVEY";
export const RESET_CREATE_SURVEY = "RESET_CREATE_SURVEY";

export const INVITE_PATIENT_ADMIN_USER = "INVITE_PATIENT_ADMIN_USER";
export const INVITE_PATIENT_ADMIN_USER_DONE = "INVITE_PATIENT_ADMIN_USER_DONE";
export const INVITE_PATIENT_ADMIN_USER_ERROR =
  "INVITE_PATIENT_ADMIN_USER_ERROR";

export const INVITE_PATIENT_SPACE_MEMBERS = "INVITE_PATIENT_SPACE_MEMBERS";
export const INVITE_PATIENT_SPACE_MEMBERS_DONE =
  "INVITE_PATIENT_SPACE_MEMBERS_DONE";
export const INVITE_PATIENT_SPACE_MEMBERS_ERROR =
  "INVITE_PATIENT_SPACE_MEMBERS_ERROR";

export const GET_SPACE_DETAILS = "GET_SPACE_DETAILS";
export const GET_SPACE_DETAILS_DONE = "GET_SPACE_DETAILS_DONE";
export const GET_SPACE_DETAILS_ERROR = "GET_SPACE_DETAILS_ERROR";

export const LOAD_STAFF = "LOAD_STAFF";
export const LOAD_STAFF_DONE = "LOAD_STAFF_DONE";
export const STAFF_RESET_LOADING = "STAFF_RESET_LOADING";
export const STAFF_INVITE = "STAFF_INVITE";
export const STAFF_INVITE_DONE = "STAFF_INVITE_DONE";
export const STAFF_INVITE_ERROR = "STAFF_INVITE_ERROR";
export const STAFF_DELETE = "STAFF_DELETE";
export const STAFF_DELETE_DONE = "STAFF_DELETE_DONE";
export const STAFF_DELETE_ERROR = "STAFF_DELETE_ERROR";

export const LOAD_CONTENT_LIBRARY = "LOAD_CONTENT_LIBRARY";
export const CONTENT_LIBRARY_LOADED = "CONTENT_LIBRARY_LOADED";
export const CONTENT_LIBRARY_RESET_LOADING = "CONTENT_LIBRARY_RESET_LOADING";
export const CONTENT_ADD = "CONTENT_ADD";
export const CONTENT_ADD_DONE = "CONTENT_ADD_DONE";
export const CONTENT_ADD_ERROR = "CONTENT_ADD_ERROR";
export const EDIT_CONTENT = "EDIT_CONTENT";
export const EDIT_CONTENT_DONE = "EDIT_CONTENT_DONE";
export const EDIT_CONTENT_ERROR = "EDIT_CONTENT_ERROR";

export const LOAD_CONTENT_LIBRARY_TOPICS = "LOAD_CONTENT_LIBRARY_TOPICS";
export const CONTENT_LIBRARY_TOPICS_LOADED = "CONTENT_LIBRARY_TOPICS_LOADED";
export const TOPIC_ADD = "TOPIC_ADD";
export const TOPIC_ADD_DONE = "TOPIC_ADD_DONE";
export const TOPIC_ADD_ERROR = "TOPIC_ADD_ERROR";

export const LOAD_PATIENT_TYPES = "LOAD_PATIENT_TYPES";
export const LOAD_PATIENT_RESET_LOADING = "LOAD_PATIENT_RESET_LOADING";
export const PATIENT_TYPES_LOADED = "PATIENT_TYPES_LOADED";

export const STORE_CARE_PLAN = "STORE_CARE_PLAN";
export const REMOVE_CARE_PLAN = "REMOVE_CARE_PLAN";
export const STORE_PATIENT_TYPE_ID = "STORE_PATIENT_TYPE_ID";

export const STORE_INFO_PANEL = "STORE_INFO_PANEL";
export const REMOVE_INFO_PANEL = "REMOVE_INFO_PANEL";

export const SHOW_TOASTER = "SHOW_TOASTER";
export const CLOSE_TOASTER = "CLOSE_TOASTER";

export const SHOW_PROGRESS_BAR = "SHOW_PROGRESS_BAR";
export const CLOSE_PROGRESS_BAR = "CLOSE_PROGRESS_BAR";

export const LOAD_CONSENT_DOCUMENT = "LOAD_CONSENT_DOCUMENT";
export const STORE_CONSENT_DOCUMENT = "STORE_CONSENT_DOCUMENT";
export const REMOVE_CONSENT_DOCUMENT = "REMOVE_CONSENT_DOCUMENT";

export const CHANGE_MPS_PACKAGE = "CHANGE_MPS_PACKAGE";
export const REMOVE_MPS_PACKAGE = "REMOVE_MPS_PACKAGE";

export const MASTER_DATA_FETCH_ON_LOAD = "MASTER_DATA_FETCH_ON_LOAD";
export const MASTER_DATA_FETCH_ON_LOAD_DONE = "MASTER_DATA_FETCH_ON_LOAD_DONE";
export const MASTER_DATA_CREATE_NEW = "MASTER_DATA_CREATE_NEW";
export const MASTER_DATA_CREATE_NEW_DONE = "MASTER_DATA_CREATE_NEW_DONE";
export const MASTER_DATA_EDIT = "MASTER_DATA_EDIT";
export const MASTER_DATA_DELETE = "MASTER_DATA_DELETE";
export const MASTER_DATA_EDIT_DONE = "MASTER_DATA_EDIT_DONE";
export const MASTER_DATA_DELETE_DONE = "MASTER_DATA_DELETE_DONE";
