import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import Select from "react-select";
import CountryCodes from "../../../FHIR/CountryCodes";
import { formatPhoneNumber } from "../../../../utils/phone";
import classnames from "classnames";

export function getSystemField(dataField) {
  if (dataField === "PatientContactPhone") {
    return "PHONE";
  } else if (dataField === "PatientContactTel") {
    return "TEL";
  } else if (dataField === "PatientContactEmail") {
    return "EMAIL";
  }
}

class ContactPoint extends Component {
  static getDisplayValue(av) {
    let value = av.valueContactPoints
      ? av.valueContactPoints
        ? av.valueContactPoints[0].value
        : null
      : null;

    let system = av.valueContactPoints
      ? av.valueContactPoints
        ? av.valueContactPoints[0].system
        : null
      : null;

    if (system === "PHONE" || system === "TEL") {
      let countryCode = av.valueContactPoints
        ? av.valueContactPoints
          ? av.valueContactPoints[0].countryCode
          : null
        : null;
      value = formatPhoneNumber(countryCode, value);
    }
    return value;
  }

  static getDisplayValueInFilter(av) {
    return av.valueContactPoints
      ? av.valueContactPoints
        ? av.valueContactPoints[0].value
        : null
      : null;
  }

  render() {
    let {
      dataField,
      attributeValue,
      setData,
      placeholder,
      hasError,
      id
    } = this.props;

    attributeValue.valueContactPoints = attributeValue.valueContactPoints || [];
    attributeValue.valueContactPoints[0] = attributeValue
      .valueContactPoints[0] || {
      system: getSystemField(dataField),
      use: "WORK"
    };
    let value = attributeValue.valueContactPoints[0].value;
    if (
      dataField === "PatientContactPhone" ||
      dataField === "PatientContactTel"
    ) {
      let countryCode = attributeValue.valueContactPoints[0].countryCode;
      return (
        <FormGroup
          className={this.props.width === 100 ? "width-100" : ""}
          style={{
            marginBottom: 0
          }}
        >
          <div className="inputHolder flex-row">
            <Select
              style={{ border: "1px solid #e0e6e8" }}
              value={countryCode}
              onChange={cc => {
                if (cc) {
                  attributeValue.valueContactPoints[0].countryCode = cc.value;
                } else {
                  attributeValue.valueContactPoints[0].countryCode = null;
                }
                setData(attributeValue);
              }}
              placeholder="Country"
              options={CountryCodes}
              labelKey="country"
              valueKey="value"
              className="mr-1"
              clearable={this.props.ccClearable}
              onBlur={this.props.onBlur}
            />
            <Input
              placeholder={placeholder}
              className={classnames(
                hasError ? "is-invalid form-control" : "form-control"
              )}
              style={{ width: "50%" }}
              value={value}
              onChange={e => {
                attributeValue.valueContactPoints[0].value = e.target.value;
                setData(attributeValue);
              }}
              type="text"
              autoComplete="nope"
              onBlur={this.props.onBlur}
              id={id}
            />
          </div>
        </FormGroup>
      );
    } else if (dataField === "PatientContactEmail") {
      return (
        <FormGroup
          className={this.props.width === 100 ? "width-100" : ""}
          style={{
            marginBottom: 0
          }}
        >
          <div className="inputHolder flex-row">
            <Input
              placeholder={placeholder}
              value={value}
              onChange={e => {
                attributeValue.valueContactPoints[0].value = e.target.value;
                setData(attributeValue);
              }}
              type="text"
              onBlur={this.props.onBlur}
            />
          </div>
        </FormGroup>
      );
    }
  }
}

export default ContactPoint;
