import React from "react";
import { Button, Col, Form, Row } from "reactstrap";
import StepAcceptTerms from "../Onboarding/StepAcceptTerms";

class UserOnboardingAcceptTerms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { confirmIdentity } = this.props;
    const { termsAccepted } = this.state;

    if (termsAccepted) {
      confirmIdentity();
    } else {
      this.setState({
        errorTerms: !termsAccepted
      });
    }
  };

  render() {
    let { termsAccepted, errorTerms } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <h3 className="text-center mb-3 font-sm-size">
          Accept Terms and Conditions
        </h3>

        <div>
          <StepAcceptTerms
            onUpdateData={this.onUpdateTermsAccepted}
            facilityInfo={this.props.facilityInfo}
            termsAccepted={termsAccepted}
          />
          {errorTerms && (
            <p className="text-muted">
              <span style={{ color: "red" }}>
                Please accept privacy policy and terms and conditions.
              </span>
            </p>
          )}
        </div>

        <Row>
          <Col xs="12">
            <div className="text-right">
              <Button color="primary" size="lg" className="px-4" block>
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default UserOnboardingAcceptTerms;
