import React, { PureComponent } from "react";
import {
  onLogin,
  onLogout,
  onSetOnboardingUserName
} from "../../State/actions/user";
import { connect } from "react-redux";
import SignupView from "./SignupView";
import userService from "../../services/userService";
import SignupFacilitySelector from "./SignupFacilitySelector";
import { parseURLQueryParams } from "../../utils/utils";
import UserOnboardingContainer from "../UserOnboarding/UserOnboardingContainer";
import MPSBarLoader from "../../components/MPSBarLoader";

class SignupContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { facilityCode: this.props.code };
  }

  componentDidMount() {
    this.props.onLogout();
    this.init();
  }

  init = () => {
    let { facilityCode } = this.state;
    if (!facilityCode) {
      return;
    }
    this.loadFacilityInfo(facilityCode).catch(e => {});
  };

  loadFacilityInfo = facilityCode => {
    this.setState({ loading: true });
    return (
      userService
        // .fetchAddPatientProfileFromCode(facilityCode)
        .fetchSignUpProfileFromCode(facilityCode)
        .then(facilityInfo => {
          if (!facilityInfo) {
            throw new Error();
          }
          this.setState({
            loading: false,
            facilityInfo,
            facilityError: false,
            facilityErrorMessage: null
          });
          return facilityInfo;
        })
        .catch(e => {
          this.setState({
            loading: false,
            facilityError: true,
            facilityErrorMessage: e.message || "Invalid link or facility code"
          });
          throw new Error();
        })
    );
  };

  doSignUp = (attributes, loginUserId, password) => {
    let formData = {
      facilityCode: this.state.facilityCode,
      patientAttributes: attributes,
      acceptedTermsAndCondition: true,
      loginUserId,
      password
    };
    return userService.signupPatient(formData);
  };

  submitCode = facilityCode => {
    this.loadFacilityInfo(facilityCode)
      .then(response => {
        this.setState({ facilityCode });
        if (response.addPatientProfile) {
          this.props.history.push(`/signup/${facilityCode}`);
        } else if (response.challengeFields) {
          this.props.history.push(`/signup/${facilityCode}?type=onboarding`);
        }
      })
      .catch(e => {});
  };

  render() {
    let {
      facilityInfo,
      loading,
      facilityCode,
      facilityError,
      facilityErrorMessage
    } = this.state;

    if (!facilityCode) {
      return (
        <SignupFacilitySelector
          submitCode={this.submitCode}
          facilityError={facilityError}
          facilityErrorMessage={facilityErrorMessage}
        />
      );
    }
    if (facilityError) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>{facilityErrorMessage}</span>
          </p>
        </div>
      );
    }

    let { signupType } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (!facilityInfo) {
      return null;
    }

    if (signupType !== "onboarding" && facilityInfo.addPatientProfile) {
      return (
        <div>
          <SignupView
            facilityError={facilityError}
            facilityErrorMessage={facilityErrorMessage}
            doSignUp={this.doSignUp}
            facilityInfo={facilityInfo}
            facilityCode={facilityCode}
            loading={loading}
            fromApp={this.props.fromApp}
            deviceType={this.props.deviceType}
          />
        </div>
      );
    }

    if (facilityInfo.challengeFields) {
      return (
        <UserOnboardingContainer
          inviteCode={facilityCode}
          facilityInfo={facilityInfo}
          onLogin={this.props.onLogin}
          onLogout={this.props.onLogout}
          onboardingType={"signup"}
          onSetOnboardingUserName={this.props.onSetOnboardingUserName}
        />
      );
    }
  }
}

const mapDispatchToProps = {
  onLogin,
  onLogout,
  onSetOnboardingUserName
};

const mapStateToProps = (state, ownProps) => {
  let params = null;
  if (ownProps.location && ownProps.location.search) {
    params = parseURLQueryParams(ownProps.location.search);
  }
  return {
    code: ownProps.match ? ownProps.match.params.code : null,
    fromApp: params ? params.fromMPSApp : null,
    deviceType: params ? params.deviceType : null,
    signupType: params ? params.type : null
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupContainer);
