import React, { PureComponent } from "react";

class SignupConfirmation extends PureComponent {
  getEmailOrPhoneText = () => {
    let { hasEmail, hasPhone } = this.props;
    if (hasEmail && hasPhone) {
      return `email and SMS`;
    }
    if (hasEmail) {
      return `email`;
    }

    if (hasEmail && hasPhone) {
      return `SMS`;
    }
  };

  render() {
    let { postSubmitMessage, fromApp } = this.props;

    return (
      <div id="self-signup-confirmation">
        {postSubmitMessage && (
          <p>
            <div dangerouslySetInnerHTML={{ __html: postSubmitMessage }} />
          </p>
        )}

        {!postSubmitMessage && (
          <p>
            Thank you for signing up with myPatientSpace. Please check your
            email or SMS and click on the link to complete the Signup process.
          </p>
        )}

        {/*{noConfirmationEmail && (*/}
        {/*  <p>*/}
        {/*    <div*/}
        {/*      dangerouslySetInnerHTML={{*/}
        {/*        __html: welcomeMessageWithoutConfirmation*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </p>*/}
        {/*)}*/}

        {fromApp && (
          <div>
            <b>
              You can close this window and return to myPatientSpace by tapping
              DONE on top right
            </b>
          </div>
        )}

        {!fromApp && (
          <div>
            <div className="mt-3">
              Please download the application on your mobile device. After
              installing the app, Sign In using your username and password.
            </div>

            <div className="my-3 d-flex justify-content-center">
              <a href="https://itunes.apple.com/app/mypatientspace/id1443558006?mt=8">
                <img
                  src="https://www.mypatientspace.com/assets/img/download-appstore.png"
                  alt="iPhone/iPad"
                  style={{ width: "135px", height: "40px" }}
                />
              </a>

              <a href="https://play.google.com/store/apps/details?id=com.mypatientspace.mypatientspace">
                <img
                  src="https://www.mypatientspace.com/assets/img/download-playstore.jpeg"
                  alt="Android"
                  style={{ width: "135px", height: "40px", marginLeft: "10px" }}
                />
              </a>
            </div>
          </div>
        )}
        {/*{noConfirmationEmail && (*/}
        {/*  <div>*/}
        {/*    If you don't remember the username or password, click*/}
        {/*    <Link className="mx-1" to="/forgot-password">*/}
        {/*      here*/}
        {/*    </Link>*/}
        {/*    to reset.*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    );
  }
}

export default SignupConfirmation;
