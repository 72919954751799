import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, CardGroup, Card, CardBody } from "reactstrap";
import userService from "../../services/userService";
import ResetPasswordForm from "./ResetPasswordForm";
import { showErrorToaster } from "../../State/actions/toaster";
import MPSBarLoader from "../../components/MPSBarLoader";
import { parseURLQueryParams } from "../../utils/utils";
import { isPatientOrMember } from "../../security/roles";
import { Link } from "react-router-dom";

class ResetPasswordView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  resetPassword = formData => {
    formData.token = this.props.match.params.token;
    this.setState({ loading: true });
    userService
      .resetPassword(formData)
      .then(response => {
        this.setState({
          status: "succeeded",
          loading: false,
          userRole: response ? response.role : null
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.showErrorToaster(
          error,
          "Password reset has failed. Please try again.",
          7
        );
      });
  };

  render() {
    let { status, loading, userRole } = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              {loading && <MPSBarLoader />}

              <CardGroup>
                <Card className="p-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img
                      src="/img/mps-logo.png"
                      height={32}
                    />
                  </div>
                  <CardBody>
                    {!status && (
                      <ResetPasswordForm
                        resetPassword={this.resetPassword}
                        linkType={this.props.linkType}
                      />
                    )}
                    {status === "succeeded" && (
                      <div>
                        {!userRole && (
                          <div className="text-center">
                            Your password has been reset. Please use the new
                            password to login.
                          </div>
                        )}

                        {isPatientOrMember(userRole) && (
                          <div>
                            <div className="text-center">
                              Your password has been reset. Please use the new
                              password to login on the mobile app.
                            </div>
                            <div className="mt-3 d-flex justify-content-center">
                              <a href="https://itunes.apple.com/app/mypatientspace/id1443558006?mt=8">
                                <img
                                  src="https://www.mypatientspace.com/assets/img/download-appstore.png"
                                  alt="iPhone/iPad"
                                  style={{ width: "135px", height: "40px" }}
                                />
                              </a>

                              <a href="https://play.google.com/store/apps/details?id=com.mypatientspace.mypatientspace">
                                <img
                                  src="https://www.mypatientspace.com/assets/img/download-playstore.jpeg"
                                  alt="Android"
                                  style={{
                                    width: "135px",
                                    height: "40px",
                                    marginLeft: "10px"
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                        )}

                        {userRole &&
                          !isPatientOrMember(userRole) && (
                            <div className="text-center">
                              Your password has been reset. Please use the new
                              password{" "}
                              <Link to={"/login"} className="cursor-pointer">
                                here
                              </Link>{" "}
                              to login.
                            </div>
                          )}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  showErrorToaster
};

const mapStateToProps = (state, ownProps) => {
  return {
    linkType:
      ownProps.location && ownProps.location.search
        ? parseURLQueryParams(ownProps.location.search).cp
        : null
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView);
