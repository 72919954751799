import React, { Component } from "react";

class PreviewWithExternalContent extends Component {
  render() {
    let article = this.props.article;
    if (!article) {
      return null;
    }

    let text = article.detailText;

    return (
      <div className="status" style={{ overflow: "auto" }}>
        <div className="media justify-content-center">
          <a href={article.contentExternalURL} target="_blank">
            {article.contentExternalURL}
          </a>
        </div>

        {text && (
          <div className="justify-content-center" style={{ display: "flex" }}>
            <div
              style={{ marginTop: "20px" }}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default PreviewWithExternalContent;
