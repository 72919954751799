import { API_ENDPOINTS } from "../config";
import { http } from "../utils/http";
import { toFormData } from "../utils/formData";
import { store } from "../State/store/configureStore";
import { closeProgress, showProgress } from "../State/actions/uploadProgress";

class FileService {
  constructor() {
    this.filesLoaded = [];
  }

  uploadFiles(type, files, thumbnails, enableCloud) {
    const data = {
      mediaType: type,
      files,
      thumbnailFiles: thumbnails
    };

    let api = API_ENDPOINTS.fileUpload();
    if (enableCloud) {
      api += "?enableCloud=true";
    }

    return http
      .request(api, "post", toFormData(data), null, progressEvent => {
        // const uploadProgress = Math.round(
        //   progressEvent.loaded * 100 / progressEvent.total
        // );
        // if (!uploadProgress || uploadProgress == 100) {
        //   store.dispatch(closeProgress());
        // } else {
        //   store.dispatch(showProgress(uploadProgress));
        // }
        // this.setState({ uploadProgress });
        const uploadProgress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        store.dispatch(showProgress(uploadProgress));
      })
      .then(data => {
        store.dispatch(closeProgress());
        if (data && data._embedded) {
          return data._embedded.contents;
        }
        return [];
      });
  }

  getFile(url) {
    // if (this.filesLoaded[url]) return this.fileLoaded[url];
    return http
      .request(url, "get", null, null, null, null, "blob")
      .then(data => {
        if (!data || data.size === 0) {
          throw new Error();
        }
        const dataConv = URL.createObjectURL(data);
        // this.fileLoaded = dataConv;
        return dataConv;
      });
  }

  deleteFile(fiLeKey) {
    return http.request(API_ENDPOINTS.contentUrl(fiLeKey), "delete");
  }
}

const fileService = new FileService();

export default fileService;
