import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import Select from "react-select";

class StringAttribute extends Component {
  static getDisplayValue(av) {
    return av.valueString ? av.valueString : null;
  }

  render() {
    let { attributeValue, setData, choiceList, options } = this.props;

    let value = attributeValue.valueString || null;

    if (choiceList) {
      return (
        <FormGroup>
          <Select
            name="form-field-name"
            value={value}
            onChange={option => {
              // attributeValue.
              setData({ valueString: option ? option.value : null });
            }}
            options={options.map(option => {
              return {
                value: option.valueString,
                label: option.valueString
              };
            })}
          />
        </FormGroup>
      );
    }

    return (
      <FormGroup>
        <div className="inputHolder flex-row">
          <Input
            value={value}
            onChange={e => {
              attributeValue.valueString = e.target.value;
              setData(attributeValue);
            }}
            type="text"
          />
        </div>
      </FormGroup>
    );
  }
}

export default StringAttribute;
