import React, { PureComponent } from "react";
import { Row, Col, Button, Input, Label, FormGroup } from "reactstrap";
import classnames from "classnames";

function validateResetPasswordForm(data) {
  let errors = {};

  // let { inputType } = data;

  // if (inputType === "EMAIL") {
  //   let validEmail = data.email && validateEmail(data.email);
  //   if (!validEmail) {
  //     errors.email = true;
  //   }
  // } else if (inputType === "PHONE") {
  //   let validPhone =
  //     data.phone && validatePhoneNumber(data.countryCode + " " + data.phone);
  //   if (!validPhone) {
  //     errors.phone = true;
  //   }
  // }

  if (!data.password) {
    errors.password = true;
  }

  if (data.password !== data.rePassword) {
    errors.passwordNotSame = true;
  }
  return errors;
}

class ResetPasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputType: this.props.linkType
        ? this.props.linkType.toUpperCase()
        : "EMAIL",
      countryCode: "+353"
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState({ fieldErrors: {} });
    let fieldErrors = validateResetPasswordForm(this.state);
    if (Object.keys(fieldErrors).length >= 1) {
      this.setState({ fieldErrors });
      return;
    }

    let { password } = this.state;

    let data = { newPassword: password };
    // if (inputType === "EMAIL") {
    //   data.email = email;
    // } else if (inputType === "PHONE") {
    //   data.phoneNumber = doE164(countryCode, phone);
    // }

    this.props.resetPassword(data);
  };

  onUpdateInputType = value => {
    this.setState({ inputType: value });
  };

  onUpdateEmail = e => {
    this.setState({ email: e.target.value });
  };

  onUpdateCountryCode = cntrObj => {
    if (cntrObj) {
      this.setState({ countryCode: cntrObj.value });
    } else {
      this.setState({ countryCode: null });
    }
  };

  onUpdatePhone = e => {
    this.setState({ phone: e.target.value });
  };

  onUpdatePassword = e => {
    this.setState({ password: e.target.value });
  };

  onUpdateRePassword = e => {
    this.setState({ rePassword: e.target.value });
  };

  render() {
    const { loading } = this.props;
    const disabled = loading;

    const { fieldErrors = {} } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <h4 className="mb-4">Reset Password</h4>

        {/*{!linkType && (*/}
        {/*<FormGroup>*/}
        {/*<Label>Received As</Label>*/}
        {/*<ButtonGroup>*/}
        {/*<Button*/}
        {/*onClick={e => {*/}
        {/*e.preventDefault();*/}
        {/*this.onUpdateInputType("EMAIL");*/}
        {/*}}*/}
        {/*className={classnames(*/}
        {/*inputType === "EMAIL" ? "btn-sm active" : "btn-sm"*/}
        {/*)}*/}
        {/*>*/}
        {/*Email*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*onClick={e => {*/}
        {/*e.preventDefault();*/}
        {/*this.onUpdateInputType("PHONE");*/}
        {/*}}*/}
        {/*className={classnames(*/}
        {/*inputType === "PHONE" ? "btn-sm active" : "btn-sm"*/}
        {/*)}*/}
        {/*>*/}
        {/*Phone*/}
        {/*</Button>*/}
        {/*</ButtonGroup>*/}
        {/*</FormGroup>*/}
        {/*)}*/}

        {/*{inputType === "EMAIL" && (*/}
        {/*<FormGroup className="mb-3">*/}
        {/*<Label for="name">Email</Label>*/}
        {/*<div className="inputHolder">*/}
        {/*<Input*/}
        {/*className={classnames(fieldErrors.email ? "is-invalid" : "")}*/}
        {/*value={email}*/}
        {/*onChange={this.onUpdateEmail}*/}
        {/*type="email"*/}
        {/*placeholder="E-mail"*/}
        {/*disabled={loading}*/}
        {/*/>{" "}*/}
        {/*<div className="invalid-feedback">Please set valid email.</div>*/}
        {/*</div>*/}
        {/*</FormGroup>*/}
        {/*)}*/}

        {/*{inputType === "PHONE" && (*/}
        {/*<FormGroup className="mb-3">*/}
        {/*<Label for="name">Phone</Label>*/}
        {/*<div className="inputHolder">*/}
        {/*<div className="d-flex">*/}
        {/*<Select*/}
        {/*style={{*/}
        {/*border: fieldErrors.phone*/}
        {/*? "1px solid #f86c6b"*/}
        {/*: "1px solid #e0e6e8",*/}
        {/*borderRadius: 0*/}
        {/*}}*/}
        {/*value={countryCode}*/}
        {/*onChange={this.onUpdateCountryCode}*/}
        {/*placeholder="Country code"*/}
        {/*options={CountryCodes}*/}
        {/*labelKey="country"*/}
        {/*valueKey="value"*/}
        {/*disabled={loading}*/}
        {/*/>*/}
        {/*<Input*/}
        {/*value={phone}*/}
        {/*onChange={this.onUpdatePhone}*/}
        {/*disabled={loading}*/}
        {/*className={classnames(*/}
        {/*fieldErrors.phone ? "is-invalid ml-1" : "ml-1"*/}
        {/*)}*/}
        {/*placeholder="Phone Number"*/}
        {/*autoComplete="nope"*/}
        {/*/>*/}
        {/*</div>*/}
        {/*<div className="invalid-feedback">Please set valid phone.</div>*/}
        {/*</div>*/}
        {/*</FormGroup>*/}
        {/*)}*/}

        <FormGroup className="mb-3">
          <Label for="name">Password</Label>
          <div className="inputHolder">
            <Input
              value={this.state.password}
              onChange={this.onUpdatePassword}
              type="password"
              autoCapitalize="none"
              placeholder="New Password"
              disabled={disabled}
              className={classnames(
                fieldErrors.password ? "is-invalid mb-3" : ""
              )}
            />
            <div className="invalid-feedback">Please set password.</div>
          </div>
        </FormGroup>

        <FormGroup className="mb-3">
          <Label for="name">Confirm Password</Label>
          <div className="inputHolder">
            <Input
              value={this.state.rePassword}
              onChange={this.onUpdateRePassword}
              type="password"
              autoCapitalize="none"
              placeholder="Re-enter New Password"
              disabled={disabled}
              className={classnames(
                fieldErrors.passwordNotSame ? "is-invalid mb-3" : ""
              )}
            />
            <div className="invalid-feedback">
              Password and re-entered password are not same.
            </div>
          </div>
        </FormGroup>

        <Row>
          <Col xs={12} className="d-flex justify-content-end">
            <Button color="primary" className="px-4" disabled={disabled}>
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default ResetPasswordForm;
