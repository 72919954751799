import React, { PureComponent } from "react";
import FileService from "../../services/fileService";
import MPSBarLoader from "../MPSBarLoader";

class ImageUrl extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ loading: true });
    FileService.getFile(this.props.url)
      .then(url => {
        this.setState({ url, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { url, loading } = this.state;
    if (!url && !loading) {
      return null;
    }

    if (this.props.noSpinner && loading) {
      return null;
    }

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    return (
      <div
        className="imageUrl"
        style={{
          overflow: this.props.overflow || "hidden"
        }}
      >
        <img
          alt=""
          src={this.state.url}
          // height={this.props.height}
          width={this.props.width}
          style={{ height: this.props.height }}
        />
      </div>
    );
  }
}

export default ImageUrl;
