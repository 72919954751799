import {
  LOAD_PATIENTS,
  LOAD_PATIENT,
  PATIENT_UPDATE,
  PATIENT_ADD,
  PATIENTS_RESET_LOADING,
  INVITE_PATIENT_ADMIN_USER,
  INVITE_PATIENT_SPACE_MEMBERS,
  GET_SPACE_DETAILS,
  LOAD_MEMBERS,
  PATIENTS_MEMBERS_RESET_LOADING,
  PATIENT_DELETE
} from "./actionTypes";

export function onLoadPatient(id) {
  return { type: LOAD_PATIENT, id };
}

export function onLoadPatients(searchCriteria) {
  return { type: LOAD_PATIENTS, searchCriteria };
}

export function onLoadMembers(patientId) {
  console.log("LOAD MEMBERS", patientId);
  return { type: LOAD_MEMBERS, patientId };
}

export function onUpdatePatient(data) {
  return { type: PATIENT_UPDATE, data: data };
}

export function onAddPatient(data) {
  return { type: PATIENT_ADD, data: data };
}

export function onDeletePatient(patientId) {
  return { type: PATIENT_DELETE, patientId };
}

export function onInviteAdminUser(patientId, data) {
  return { type: INVITE_PATIENT_ADMIN_USER, patientId, data };
}

export function onInviteSpaceMember(patientId, data) {
  return { type: INVITE_PATIENT_SPACE_MEMBERS, patientId, data };
}

export function onGetSpaceDetails(inviteCode, email) {
  // console.log('action GET SPACEDETAILS', email, inviteCode);
  return { type: GET_SPACE_DETAILS, inviteCode, email };
}

export function onResetLoading() {
  return { type: PATIENTS_RESET_LOADING };
}
export function onResetLoadingMembers() {
  return { type: PATIENTS_MEMBERS_RESET_LOADING };
}
