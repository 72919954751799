import { Input } from "reactstrap";
import React from "react";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import ModalPopup from "../../components/ModalPopup";
import MYPPrivacyPolicy from "./MYPPrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";

class StepAcceptTerms extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // onShowSpaceTerms = () => {
  //   this.setState({ showSpaceTerms: true });
  // };
  //
  // onHideSpaceTerms = () => {
  //   this.setState({ showSpaceTerms: false });
  // };

  onShowTerms = () => {
    this.setState({ showTerms: true });
  };

  onHideTerms = () => {
    this.setState({ showTerms: false });
  };

  onShowPrivacy = () => {
    this.setState({ showPrivacy: true });
  };

  onHidePrivacy = () => {
    this.setState({ showPrivacy: false });
  };

  onTermsClicked = () => {
    this.props.onUpdateData("termsAccepted", !this.props.termsAccepted);
  };

  getTermsNode() {
    // let { facilityInfo } = this.props;

    // let termsContent = null,
    //   facilityName = null;
    // if (facilityInfo) {
    //   termsContent = facilityInfo.termsAndConditions;
    //   facilityName = facilityInfo.facilityName;
    // }

    return (
      <div style={{ width: "100%" }}>
        I have read and accept the
        <MPSButton type="link" className="ml-1" onClick={this.onShowTerms}>
          terms &amp; conditions
        </MPSButton>
        <span className="ml-1">and</span>
        <MPSButton type="link" className="ml-1" onClick={this.onShowPrivacy}>
          privacy policy
        </MPSButton>
        .
      </div>
    );

    // return (
    //   <div style={{ width: "100%" }}>
    //     I have read and accept the
    //     {termsContent &&
    //       facilityName && (
    //         <span>
    //           <MPSButton
    //             type="link"
    //             className="ml-1"
    //             onClick={this.onShowSpaceTerms}
    //           >
    //             {facilityName + "  terms & conditions"}
    //           </MPSButton>
    //           ,
    //         </span>
    //       )}
    //     <MPSButton type="link" className="ml-1" onClick={this.onShowTerms}>
    //       myPatientSpace terms &amp; conditions
    //     </MPSButton>
    //     <span className="ml-1">and</span>
    //     <MPSButton type="link" className="ml-1" onClick={this.onShowPrivacy}>
    //       privacy policy
    //     </MPSButton>
    //     .
    //   </div>
    // );
  }

  render() {
    let { loading, termsAccepted, facilityInfo } = this.props;

    let termsAndConditionsLibrary = null;
    let privacyPolicyLibrary = null;
    if (facilityInfo) {
      termsAndConditionsLibrary = facilityInfo.termsAndConditionsLibrary;
      privacyPolicyLibrary = facilityInfo.privacyPolicyLibrary;
    }

    return (
      <div>
        <div className="mb-3 d-flex">
          <div>
            <Input
              type="checkbox"
              className="ml-0 mr-3"
              style={{ position: "relative", marginTop: "0px" }}
              checked={termsAccepted}
              onChange={this.onTermsClicked}
              disabled={loading}
            />
          </div>
          {this.getTermsNode()}
        </div>

        {/*{this.state.showSpaceTerms && (*/}
        {/*  <ModalPopup*/}
        {/*    title={facilityName + " Terms and Conditions"}*/}
        {/*    onClose={this.onHideSpaceTerms}*/}
        {/*    className="myp-terms-modal"*/}
        {/*  >*/}
        {/*    <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>*/}
        {/*      <TermsContent termsContent={termsContent} />*/}
        {/*    </div>*/}
        {/*    <div className="d-flex justify-content-center mt-2">*/}
        {/*      <a*/}
        {/*        className="btn btn-dark btn-lg"*/}
        {/*        style={{ color: "#fff", width: "200px" }}*/}
        {/*        onClick={this.onHideSpaceTerms}*/}
        {/*      >*/}
        {/*        Close*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </ModalPopup>*/}
        {/*)}*/}

        {this.state.showTerms && (
          <ModalPopup
            title="myPatientSpace Terms and Conditions"
            onClose={this.onHideTerms}
            className="myp-terms-modal"
          >
            <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
              <TermsAndConditions
                termsAndConditionsLibrary={termsAndConditionsLibrary}
              />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <a
                className="btn btn-dark btn-lg"
                style={{ color: "#fff", width: "200px" }}
                onClick={this.onHideTerms}
              >
                Close
              </a>
            </div>
          </ModalPopup>
        )}

        {this.state.showPrivacy && (
          <ModalPopup
            title="Privacy Policy"
            onClose={this.onHidePrivacy}
            className="myp-terms-modal"
          >
            <div style={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
              <PrivacyPolicy privacyPolicyLibrary={privacyPolicyLibrary} />
            </div>
            <div className="d-flex justify-content-center mt-2">
              <a
                className="btn btn-dark btn-lg"
                style={{ color: "#fff", width: "200px" }}
                onClick={this.onHidePrivacy}
              >
                Close
              </a>
            </div>
          </ModalPopup>
        )}
      </div>
    );
  }
}

// const TermsContent = ({ termsContent }) => (
//   <div id="placeholders" dangerouslySetInnerHTML={{ __html: termsContent }} />
// );

export default StepAcceptTerms;
