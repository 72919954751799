import React, { PureComponent } from "react";
import fileService from "../../services/fileService";
import MPSBarLoader from "../../components/MPSBarLoader";
import { isAbsoluteURL } from "../../utils/utils";

class VideoPlayer extends PureComponent {
  static propTypes = {};

  static defaultProps = {
    ...PureComponent.defaultProps
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    fileService.getFile(this.props.thumbnailURL).then(data => {
      this.setState({ thumbnail: data });
    });
  }

  onClick = () => {
    this.setState({ loading: true });
    fileService
      .getFile(this.props.url)
      .then(data => {
        this.setState({ dataUrl: data, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { dataUrl, thumbnail, loading } = this.state;
    const { url } = this.props;

    if (isAbsoluteURL(url)) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <video
            width={"90%"}
            height="360px"
            controls
            src={url}
            preload="none"
            poster={thumbnail}
          >
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      );
    }

    if (!dataUrl) {
      return (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div
            className={"d-flex justify-content-center align-items-center"}
            style={{
              height: "360px",
              width: "90%",
              backgroundImage: "url('" + thumbnail + "')",
              backgroundColor: "#f1f1f1",
              backgroundPosition: "center",
              backgroundSize: "cover",
              borderRadius: "3px"
            }}
          >
            {!loading && (
              <a
                className="d-flex justify-content-center align-items-center cursor-pointer"
                onClick={this.onClick}
                style={{
                  backgroundColor: "#f1f1f1",
                  padding: "15px",
                  borderRadius: "50%"
                }}
              >
                <span
                  className="fas fa-play"
                  style={{
                    paddingLeft: 1,
                    color: "#333"
                  }}
                />
              </a>
            )}
            {loading && (
              <div style={{ margin: "100px auto", width: 50 }}>
                <MPSBarLoader />
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        <video controls src={dataUrl} width={"90%"} height="360px">
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    );
  }
}

export default VideoPlayer;
