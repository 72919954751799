import { isStaff, ROLE_MPS_ADMIN } from "../security/roles";
import StorageService from "../services/storageService";
import { API_URL_KEY } from "../utils/http";
export const REACT_APP_ENV = process.env.REACT_APP_ENV || "development";
export const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
export const API_CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET;
const IRELAND_DEV = "https://testservice.mypatientspace.com";
const IRELAND_PROD = "https://api.mypatientspace.com";

export function isProd() {
  return REACT_APP_ENV === "production";
}

export function getAppURL() {
  if (isProd()) {
    return "https://web.mypatientspace.com";
  }
  return "https://testweb.mypatientspace.com";
}

const API_HOSTS = {
  development: [
    { region: "Ireland", url: IRELAND_DEV },
    { region: "UAE", url: "https://myps.quazgar.net" }
  ],
  production: [{ region: "Ireland", url: IRELAND_PROD }]
};

function setDefaultAPIURL() {
  if (StorageService.getFromLocalStorage(API_URL_KEY)) {
    return;
  }
  if (isProd()) {
    StorageService.addToLocalStorage(API_URL_KEY, IRELAND_PROD);
  } else {
    StorageService.addToLocalStorage(API_URL_KEY, IRELAND_DEV);
  }
}
setDefaultAPIURL();

// export const API_DOMAIN = process.env.REACT_APP_MYP_API_URL
//   ? process.env.REACT_APP_MYP_API_URL
//   : "testservice.mypatientspace.com";
// export const DEFAULT_API_URL = "https://testservice.mypatientspace.com";
// export const API_URL = "https://" + API_DOMAIN;
// export const API_URL = "http://localhost:8080";

export const API_ENDPOINTS = {
  locateUser: `/api/signup/locateUser`,
  get_token: "/oauth/token",
  get_user_info: "/api/users/userDetails",
  acceptInvite: () => `/api/signup/acceptInvite`,
  fetchSpaceDetailsFromInviteCode: () =>
    `/api/signup/fetchSpaceDetailsFromInviteCode/`,
  acceptTerms: () => `/api/signup/acceptTermsAndConditions`,
  confirmationCode: () => `/api/signup/activateWith2FACode`,
  confirmationCodeWithTerms: () => `/api/signup/activateWithTerms`,
  setCredentials: () => `/api/signup/setLoginCredentials`,
  getSpaceInfo: () => "/api/space",
  getFacilityInfo: () => "/api/admin/facility",
  getAllFacilities: () => "/api/mpsadmin/facilities",
  loadOrganization: id => `/api/mpsadmin/facilities/${id}`,
  updateFacility: (role, id) =>
    role === ROLE_MPS_ADMIN
      ? `/api/mpsadmin/facilities/${id}`
      : `/api/admin/facility`,
  addFacility: () => "/api/mpsadmin/facilities/",
  loadAdmins: id => `/api/mpsadmin/facilities/${id}/admin`,
  inviteAdmin: (orgId, withoutInvite) =>
    withoutInvite
      ? `/api/mpsadmin/facilities/${orgId}/admin/unauth`
      : `/api/mpsadmin/facilities/${orgId}/admin`,
  deleteAdmin: (orgId, id) => `/api/mpsadmin/facilities/${orgId}/admin/${id}`,
  loadStaff: () => `/api/admin/staff/`,
  loadAllStaffForStaff: () => `/api/staff/staffs`,
  loadOneStaff: staffId => `/api/admin/v1/staff/${staffId}`,
  inviteStaff: withoutInvite =>
    withoutInvite ? `/api/admin/staff/withoutinvite` : `/api/admin/staff`,
  deleteStaff: staffId => `/api/admin/staff/${staffId}`,
  editStaff: staffId => `/api/admin/staff/${staffId}`,
  loadPatients: () => "/api/staff/v1/getpatients",
  loadPatient: patientId =>
    `/api/staff/patients/${
      patientId // loadPatients: () => "/api/staff/patients/",
    }/details`,
  inviteAdminUser: (patientId, withoutInvite) =>
    withoutInvite
      ? `/api/staff/patients/${patientId}/adminmember/withoutinvite`
      : `/api/staff/patients/${patientId}/adminmember/`,
  inviteSpaceMember: (patientId, withoutInvite) =>
    withoutInvite
      ? `/api/staff/patients/${patientId}/member/withoutinvite`
      : `/api/staff/patients/${patientId}/member/`,
  getSpaceDetails: () => `/api/fetchSpaceDetailsForInviteCode`,
  addPatient: withoutInvite =>
    withoutInvite ? "/api/staff/patients/withoutinvite" : "/api/staff/patients",
  addPatientWithoutInvite: () => "/api/staff/patients/withoutinvite",
  deletePatient: patientId => `/api/staff/patients/${patientId}`,
  loadPatientTypesTemplate: () => `/api/artifact/mpstemplate`,
  loadPatientTypes: () => `/api/artifact/patienttypes`,
  loadSinglePatientType: id => `/api/artifact/patienttypes/${id}`,
  loadPatientTypeDocumentTopics: id =>
    `/api/artifact/masterdata/ptype/${id}/documenttopics`,
  addPatientType: () => `/api/artifact/patienttypes`,
  editPatientType: id => `/api/artifact/patienttypes/${id}`,
  addProfileAttribute: profileId =>
    `/api/artifact/patienttypes/${profileId}/attributes`,
  updateProfileAttribute: (profileId, attributeId) =>
    `/api/artifact/patienttypes/${profileId}/attributes/${attributeId}`,
  deleteProfileAttribute: (profileId, attributeId) =>
    `/api/artifact/patienttypes/${profileId}/attributes/${attributeId}`,
  addProfileMarkup: profileId =>
    `/api/artifact/patienttypes/${profileId}/screens`,
  loadMembers: patientId => `/api/staff/patients/${patientId}/members`,
  update_patient: "/api/patients/",
  addStatus: (role, patientId) =>
    isStaff(role)
      ? `/api/staff/patients/${patientId}/status `
      : `/api/patient/status`,
  addStatusFromSurvey: (patientId, surveyId) =>
    `/api/staff/patients/${patientId}/assignSurvey/${surveyId}`,
  addLibraryStatus: (patientId, libraryId) =>
    `/api/staff/patients/${patientId}/assignLibrary/${libraryId}`,
  addConsentDocumentStatus: (patientId, consentId) =>
    `/api/staff/patients/${patientId}/assignConsent/${consentId}`,
  loadStatuses: (role, patientId) =>
    isStaff(role)
      ? `/api/staff/patients/${patientId}/artifacts?artifactType=STATUSES`
      : `/api/patient/artifacts?artifactType=STATUSES`,
  loadStaffNotes: patientId =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=STAFF_NOTES`,
  addStatusTemplate: () => `/api/artifact/messagetemplates`,
  loadStatusTemplates: () => `/api/artifact/messagetemplates`,
  loadTemplateParameters: type =>
    `/api/artifact/messageparameters?messageType=${type}&page=0&size=100`,
  loadSurveys: () => "/api/artifact/surveys/",
  loadSurvey: surveyId => "/api/artifact/surveys/" + surveyId,
  addSurvey: () => "/api/artifact/surveys/",
  fileUpload: () => "/api/uploadFiles",
  loadContentLibrary: () => "/api/artifact/libraries?allStatus=True",
  addLibraryContent: () => "/api/artifact/libraries",
  loadTopics: () => "/api/artifact/librarytopics",
  loadArticle: articleId => "/api/artifact/libraries/" + articleId,
  loadArticlePatient: articleId => "/api/libraries/" + articleId,
  addLibraryTopic: () => "/api/artifact/librarytopics",
  loadTopic: topicId => "/api/artifact/librarytopics/" + topicId,
  addInfoPanel: patientTypeId =>
    "/api/artifact/patienttypes/" + patientTypeId + "/infopanel",
  loadPredefinedItems: patientTypeId =>
    "/api/artifact/patienttypes/" + patientTypeId + "/predefineditems",
  contentUrl: fileKey => "/api/content?fileKey=" + fileKey,
  loadPatientSurveys: patientId =>
    "/api/staff/patients/" +
    patientId +
    "/artifacts?artifactType=SURVEY_RESPONSES&page=0&size=30",
  loadAllPackages: () => "/api/mpsadmin/packages",
  loadProvisionedPackages: () => "/api/artifact/provisionedPackages/",
  carePlan: () => "/api/artifact/careplans/",
  carePlanById: id => "/api/artifact/careplans/" + id,
  carePlansByPatientType: patientTypeId =>
    "/api/artifact/careplans/patienttypes/" + patientTypeId,
  carePlansByPatient: patientId =>
    "/api/staff/patients/" + patientId + "/careplanView",
  assignCarePlanToPatient: patientId =>
    "/api/staff/patients/" + patientId + "/assignCarePlan",
  expressions: patientTypeId =>
    `/api/artifact/careplans/expressions/patient-type/${
      patientTypeId // expressions: () => "/api/artifact/careplans/expressions",
    }`,
  expressionsForMps: () => "/api/mpsadmin/careplans/expressions",
  consentDocuments: () => "/api/artifact/consentDocuments",
  consultants: "/api/consultants",
  messageTemplate: templateId => "/api/artifact/messagetemplates/" + templateId,
  getPatientProfile: patientId =>
    "/api/staff/patients/" + patientId + "/profile",
  loadPatientDocuments: patientId =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=PATIENT_DOCUMENTS`,
  loadPatientDevices: patientId =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=DEVICES`,
  updatePatientProfileAttribute: patientId =>
    `/api/staff/patients/${patientId}/profile/attributes`,
  loadMyPatients: "/api/staff/mypatients",
  uiAttributeDataFields: "/api/artifact/attributeDataFields?size=500",
  uiAttributeDataFieldsMPS: "/api/mpsadmin/attributeDataFields",
  forgotPassword: "/api/signup/forgotPasswordResetRequest",
  resetPassword: "/api/signup/forgotPasswordSetNewPassword",
  addMember: (withoutInvite, patientId) =>
    withoutInvite
      ? "/api/staff/patients/" + patientId + "/member/withoutinvite"
      : "/api/staff/patients/" + patientId + "/member/",
  addAdminMember: (withoutInvite, patientId) =>
    withoutInvite
      ? "/api/staff/patients/" + patientId + "/adminmember/withoutinvite"
      : "/api/staff/patients/" + patientId + "/adminmember/",
  loadStaffProfile: "/api/staff/",
  loadFacilityAdminProfile: "/api/admin/",
  loadMpsAdminProfile: "/api/mpsadmin/",
  removeMember: (patientId, memberId) =>
    "/api/staff/patients/" + patientId + "/members/delete?id=" + memberId,
  editMember: (patientId, memberId) =>
    `/api/staff/patients/${patientId}/member/${memberId}/details`,
  createMPSPackage: (facilityId, patientTypeId) =>
    `/api/mpsadmin/facilities/${
      facilityId // createMPSPackage: `/api/mpsadmin/mpspackages/`,
    }/patientTypes/${patientTypeId}/mpspackages`,
  loadMPSPackage: packageId => `/api/mpsadmin/mpspackages/${packageId}`,
  proceduresList: patientTypeId =>
    `/api/artifact/patienttypes/${patientTypeId}/attributes/name/MPS_PATIENT_PROCEDURE`,
  assessmentStats: "/api/artifact/v1/getassessments/",
  patientTypesForPackageForImport: packageId =>
    `/api/mpsadmin/patientTypesForPackageId?packageId=${
      packageId // assessmentStats: "/api/artifact/getassessments",
    }`,
  patientTypesForPackageForExport: packageId =>
    `/api/mpsadmin/allPatientTypesForPackageId?packageId=${packageId}`,
  importFromFacility: packageId =>
    `/api/mpsadmin/mpspackages/${
      packageId //   `/api/mpsadmin/mpspackages/${packageId}/updatefromFacilities/${facilityId}/patientTypes/${patientTypeId}`, // importFromFacility: (packageId, facilityId, patientTypeId) =>
    }/updateFromFacilities`,
  exportToFacility: (packageId, facilityId, patientTypeId) =>
    `/api/mpsadmin/facilities/${facilityId}/patientTypes/${patientTypeId}/updateFromPackage/${packageId}`,
  careTeams: `/api/admin/careteams`,
  reInvitePatient: patientId => `/api/staff/patients/${patientId}/reinvite`,
  reInviteStaff: staffId => `/api/admin/staff/${staffId}/reinvite`,
  mpsPackageDuplicate: (id, name) =>
    `/api/mpsadmin/mpspackages/${id}/cloneWithName/${name}`,
  mpsPackages: `/api/mpsadmin/mpspackages`,
  carePlanAsCSV: patientId => `/api/staff/patients/${patientId}/careplan-csv`,
  fireCompleteCarePlan: patientId =>
    `/api/staff/patients/${patientId}/fire-complete-careplan`,
  tagPatient: patientId => `/api/staff/mypatients/${patientId}`,
  restorePatient: patientId => `/api/staff/patients/${patientId}/reenable`,
  allSpaces: `/api/spaces?allStatus=true`,
  changeSpace: newSpaceId => `api/spaces/${newSpaceId}/currentspace`,
  editFacilityAdmin: (facilityId, staffId) =>
    `/api/mpsadmin/facilities/${facilityId}/admin/${staffId}`,
  taskTemplates: patientTypeId =>
    `/api/artifact/patienttypes/${patientTypeId}/tasktemplates`,
  taskTemplate: (patientTypeId, templateId) =>
    `/api/artifact/patienttypes/${patientTypeId}/tasktemplates/${templateId}`,
  tasks: patientId => `/api/staff/patients/${patientId}/tasks`,
  myTasks: `/api/staff/inbox/mytasks`,
  allTasks: `/api/staff/inbox/tasks`,
  outbox: `/api/staff/outbox/mytasks`,
  changePassword: `/api/updatePasswordOfLoggedInUser`,
  validateCarePlans: `/api/artifact/careplans/validate`,
  taskAssigneesForStaff: `/api/staff/staffandcareteams`,
  taskAssigneesForAdmin: `/api/admin/staffandcareteams`,
  acceptTask: taskId => `/api/staff/tasks/${taskId}/accept`,
  completeTask: taskId => `/api/staff/tasks/${taskId}/complete`,
  reassignTask: taskId => `/api/staff/tasks/${taskId}/reassign`,
  task: taskId => `/api/staff/tasks/${taskId}`,
  executeTaskTemplate: (templateId, patientId) =>
    `/api/staff/tasktemplates/${templateId}/patients/${patientId}`,
  facilityPatientStats: facilityId =>
    `/api/mpsadmin/facilities/${facilityId}/stats`,
  facilityAssignedPackages: facilityId =>
    `/api/mpsadmin/facilities/${facilityId}/mpspackages`,
  patientsWithBasicDetails: `/api/staff/patients/light`,
  addPackage: (facilityId, packageId) =>
    `/api/mpsadmin/facilities/${facilityId}/addNewPackage/${packageId}`,
  patientMembers: "/api/patient/members",
  patientDocuments: "/api/patient/artifacts?artifactType=PATIENT_DOCUMENTS",
  addDocument: patientId =>
    `/api/staff/patients/${
      patientId //patients app
    }/assignDocument/?sendAsStatusMessage=false`,
  patientDevices: "/api/patient/artifacts?artifactType=DEVICES",
  uiAttributesForFields: "/api/artifact/allUIFields",
  fieldsConfig: "/api/artifact/attributeUIFields",
  masterData: "/api/artifact/masterdata",
  masterDataEdit: id => `/api/artifact/masterdata/${id}`,
  reopenTask: id => `/api/staff/tasks/${id}/reopen`,
  availableFeatures: `/api/mpsadmin/getAllAvailableFeatures`,
  facilitiesAndPatientTypes: `/api/mpsadmin/packageHelperPatientTypes?withoutPackages=true`,
  changeLogs: patientId =>
    `/api/staff/patients/${patientId}/logs?auditType=CHANGE_LOG`,
  accessLogs: patientId =>
    `api/staff/patients/${patientId}/logs?auditType=ACCESS_LOG`,
  // patientStats: `/api/artifact/v1/getPatientData`,
  patientStats: `/api/artifact/v2/getPatientData`,
  surveyResponse: messageId =>
    `/api/patient/messages/${messageId}/surveyresponses`,
  taskUserParameters: `/api/artifact/taskUserParameters`,
  campaigns: "/api/artifact/campaigns",
  deleteFacilityOTP: facilityId =>
    `/api/mpsadmin/facilities/${facilityId}/getotp`,
  deleteFacility: (facilityId, OTP) =>
    `/api/mpsadmin/facilities/${facilityId}/withotp/${OTP}`,
  allResmedLocations: `/api/admin/mps/meta?type=LOCATIONS`,
  resmedUsers: locId =>
    `/api/admin/mps/meta?type=CLINICAL_USERS&locId=${locId}`,
  detailedAssessmentReport: patientId =>
    `/api/staff/patients/${patientId}/detailedReport`,
  therapyComplianceReport: patientId =>
    `/api/staff/patients/${patientId}/therapyComplianceReport`,
  mpsAdminPatientStats: facilityId =>
    `api/mpsadmin/facilties/${facilityId}/v2/getPatientData`,
  restoreStaff: staffId => `/api/admin/staff/${staffId}/undelete`,
  resetStaffPassword: staffId => `/api/admin/staff/${staffId}/resetPassword`,
  carePlanAsHTML: carePlanId => `/api/artifact/careplan/export/${carePlanId}`,
  exportPatientTypePackage: patientTypeId =>
    `/api/artifact/patientTypes/${patientTypeId}/mpspackages/export`,
  importPatientTypePackage: "/api/artifact/mpspackages/import",
  allTaskTemplates: "/api/artifact/tasktemplates",
  staffTaskReport: "/api/staff/report/tasks",
  staffTaskReportTasks: "/api/staff/report/getTasks",
  taskAssigneesForTaskReport: `/api/staff/report/staffandcareteams`,
  previewInviteReminder: `/api/admin/remindForInvite`,
  previewInactivityReminder: `/api/admin/remindForInActivity`,
  previewSurveyCompletionReminder: `/api/admin/remindForSurveyIncompletion`,
  dbStats: facilityId => `/api/mpsadmin/facilities/${facilityId}/dbStats`,
  mpsAdminTemplateParameters: `/api/mpsadmin/messageparameters`,
  challengeFields: code => `/api/signup/fetchChallengeFields/${code}`,
  challengeFieldsFromFacilityCode: code =>
    `/api/signup/fetchChallengeFieldsFromFacilityCode/${code}`,
  fetchAddPatientProfileFromCode: code =>
    `/api/signup/fetchAddPatientProfileFromCode/${code}`,
  reminderAudits: `/api/admin/getReminderAuditLog`,
  acceptInviteV1: `/api/signup/v1/acceptInvite`,
  setLoginCredentialsV1: `/api/signup/v1/setLoginCredentials`,
  resetPatientCredentials: patientId =>
    `/api/staff/patients/${patientId}/setLoginCredentials`,
  staffStats: `/api/artifact/getStaffData`,
  mpsAdminStaffStats: facilityId =>
    `/api/mpsadmin/facilties/${facilityId}/getStaffData`,
  mpsAdminArtifactsStats: facilityId =>
    `/api/mpsadmin/facilties/${facilityId}/getArtifactsUsages`,
  artifactsStats: `/api/artifact/getArtifactsUsages`,
  patientSignUp: `/api/signup/patients`,
  masterDataCSVUpload: `/api/artifact/masterdataFromCSV`,
  emailConfirmation: code => `/api/signup/confirm/${code}`,
  signUpValidation: `/api/signup/validate`,
  signupProfileFromCode: code => `/api/signup/fetchProfileFromCode/${code}`,
  statusById: (patientId, statusId) =>
    `/api/staff/patients/${patientId}/status/${statusId}`,
  documentById: (patientId, docId) =>
    `/api/staff/patients/${patientId}/patientDocuments/${docId}`,
  loadLibForSignUp: libId => `api/signup/libraries/${libId}`,
  MPSAdminPatients: key => `/api/mpsadmin/patients?searchTerm=${key}`,
  markStatusAsRead: `/api/patient/setMessagesAsRead`,
  loadPersonalNotes: patientId =>
    `/api/staff/patients/${patientId}/artifacts?artifactType=PERSONAL_NOTES`,
  patientCustomReport: patientId =>
    `/api/staff/patients/${patientId}/getCustomReport`,
  patientCustomReportAnonymous: patientTypeId =>
    `/api/admin/getCustomReport?pTypeId=${patientTypeId}`,
  appointmentSchedule: `/api/artifact/schedules`,
  appointments: `/api/artifact/appointments`,
  appointmentTemplates: `/api/artifact/appttemplates`
};

export const PAGE_SIZE = 20;

export function getServerRegions() {
  return API_HOSTS[REACT_APP_ENV];
}
