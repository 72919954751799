import React, { PureComponent } from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import MPSBarLoader from "../../components/MPSBarLoader";

class UserOboardingLoadingIndicator extends PureComponent {
  render() {
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="ob-logos my-4">
                <div className="p-3">
                  <img
                    alt="myPatientSpace"
                    src="/img/mps-logo.png"
                    height={32}
                  />
                </div>
              </div>

              <CardGroup>
                <Card className="p-md-4 p-sm-3">
                  <CardBody>
                    <div style={{ margin: "100px auto", width: 100 }}>
                      <MPSBarLoader />
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserOboardingLoadingIndicator;
