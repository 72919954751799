import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import Select from "react-select";

class CCCObservation extends Component {
  static getDisplayValue(attributeValue) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value &&
      attributeValue.valueObservation.value.valueCode
    ) {
      return (
        attributeValue.valueObservation.value.valueCode.code +
        " - " +
        attributeValue.valueObservation.value.valueCode.display
      );
    }
  }

  static getOptionsList(options) {
    return options.map(option => {
      let val = option.valueObservation.value.valueCode;
      return {
        value: val.code,
        label: val.code + " - " + val.display
      };
    });
  }

  render() {
    let { attributeValue, choiceList, options, setData } = this.props;

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueCode
      ? attributeValue.valueObservation.value.valueCode.code
      : null;

    if (choiceList) {
      return (
        <FormGroup>
          <div className="inputHolder flex-row">
            <Select
              name="form-field-name"
              value={obsVal}
              onChange={option => {
                if (option) {
                  // attributeValue.valueObservation.value = option.valObj;
                  attributeValue = option.valObj;
                  // attributeValue.valueObservation.value.valueCode =
                  //   option.value;
                  // attributeValue.valueObservation.code =
                  //   attributeValue.valueObservation.code || observationCode;
                } else {
                  attributeValue.valueObservation.value = {};
                  // attributeValue.valueObservation.code = {};
                }
                setData(attributeValue);
              }}
              options={options.map(option => {
                let val = option.valueObservation.value.valueCode;
                return {
                  value: val.code,
                  valObj: option,
                  label: val.code + " - " + val.display
                };
              })}
            />
          </div>
        </FormGroup>
      );
    } else {
      return (
        <FormGroup>
          <div className="inputHolder flex-row">
            <Input
              value={obsVal}
              onChange={e => {
                attributeValue.valueObservation.value.valueCode =
                  attributeValue.valueObservation.value.valueCode || {};
                attributeValue.valueObservation.value.valueCode.code =
                  e.target.value;
                setData(attributeValue);
              }}
              type="text"
              placeholder="Code"
            />

            <Input
              value={obsVal}
              onChange={e => {
                attributeValue.valueObservation.value.valueCode =
                  attributeValue.valueObservation.value.valueCode || {};
                attributeValue.valueObservation.value.valueCode.display =
                  e.target.value;
                setData(attributeValue);
              }}
              type="text"
              placeholder="Display"
            />
          </div>
        </FormGroup>
      );
    }
  }
}

export default CCCObservation;
