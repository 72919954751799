import React, { PureComponent } from "react";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";

class CredentialsPresentMessage extends PureComponent {
  getEmailAndPhone = () => {
    let { email, phone } = this.props;

    if (email && phone) {
      return `${email}/phone`;
    }

    if (email) {
      return `${email}`;
    }

    if (phone) {
      return `${phone}`;
    }

    return null;
  };

  render() {
    let { facilityName } = this.props;

    return (
      <div style={{ color: "#28623c" }} className="mb-4">
        {`Welcome back! You have just joined ${facilityName}. You already have an account with myPatientSpace associated with ${this.getEmailAndPhone()}. `}
        <br /><br />
        If you don't remember the password, click
        <MPSButton
          type="link"
          className="mx-1"
          onClick={() => {
            this.props.history.push("/forgot-password");
          }}
          target="_blank"
        >
          here
        </MPSButton>
        to reset.
      </div>
    );
  }
}

export default CredentialsPresentMessage;
