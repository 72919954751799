import React from "react";

export default () => (
  <svg width="11px" height="13px" viewBox="0 0 11 13" version="1.1">
    <title>Icon/Sound/Default</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="4.0-Home-&amp;-Statuss"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-6" transform="translate(-126.000000, -304.000000)">
        <g
          id="Icon/Sound/Default"
          transform="translate(127.000000, 304.000000)"
        >
          <g id="mic">
            <path
              d="M4.5,7.63636364 C3.1,7.63636364 2,6.43636364 2,4.90909091 L2,2.72727273 C2,1.2 3.1,0 4.5,0 C5.9,0 7,1.2 7,2.72727273 L7,4.90909091 C7,6.43636364 5.9,7.63636364 4.5,7.63636364 Z"
              id="Shape"
              fill="#6E7C99"
            ></path>
            <path
              d="M9,4.90909091 C9,7.63636364 7,9.81818182 4.5,9.81818182 C2,9.81818182 0,7.63636364 0,4.90909091"
              id="Shape"
              stroke="#6E7C99"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M2,12 L7,12"
              id="Shape"
              stroke="#6E7C99"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M4.5,9.81818182 L4.5,12"
              id="Shape"
              stroke="#6E7C99"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
