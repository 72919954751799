import React, { PureComponent } from "react";
import fileService from "../../services/fileService";
import { isAbsoluteURL } from "../../utils/utils";
import MPSBarLoader from "../../components/MPSBarLoader";

class AudioPlayer extends PureComponent {
  static propTypes = {};

  static defaultProps = {
    ...PureComponent.defaultProps
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  onClick = () => {
    this.setState({ loading: true });
    fileService
      .getFile(this.props.url)
      .then(data => {
        this.setState({ dataUrl: data, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { dataUrl, loading } = this.state;
    const { url } = this.props;

    if (isAbsoluteURL(url)) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <audio controls src={url} preload="none">
            Sorry, your browser doesn't support embedded videos.
          </audio>
        </div>
      );
    }

    if (!dataUrl) {
      return (
        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          {!loading && (
            <a
              className="d-flex justify-content-center align-items-center cursor-pointer"
              onClick={this.onClick}
              style={{
                backgroundColor: "#f1f1f1",
                padding: "15px",
                borderRadius: "50%"
              }}
            >
              <span
                className="fas fa-volume-up"
                style={{
                  paddingLeft: 1,
                  color: "#333"
                }}
              />
            </a>
          )}

          {loading && (
            <div style={{ margin: "100px auto", width: 50 }}>
              <MPSBarLoader />
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-center align-items-center">
        <audio controls src={dataUrl}>
          Sorry, your browser doesn't support embedded videos.
        </audio>
      </div>
    );
  }
}

export default AudioPlayer;
