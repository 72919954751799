import ContactPoint from "./Elements/ContactPoint";
import AddressAttribute from "./Elements/AddressAttribute";
import PositionAttribute from "./Elements/PositionAttribute";
import StringObservation from "./Elements/StringObservation";
import CCCObservation from "./Elements/CCCObservation";
import HumanNameAttribute from "./Elements/HumanNameAttribute";
import CodeableConceptCode from "./Elements/CodeableConceptCode";
import PlaceholderAttribute from "./Elements/PlaceholderAttribute";
import StringAttribute from "./Elements/StringAttribute";
import IntegerAttribute from "./Elements/IntegerAttribute";
import DateObservation from "./Elements/DateObservation";

let OBSERVATION_TYPES = [
  "OBSERVATION",
  "OBSERVATION_BOOLEAN",
  "OBSERVATION_DATE",
  "OBSERVATION_INTEGER",
  "OBSERVATION_DOUBLE",
  "OBSERVATION_QUANTITY",
  "OBSERVATION_CODEABLE_CONCEPT_CODE"
];

let CHOICE_LIST_TYPES = [
  "STRING",
  "INTEGER",
  "DATE",
  "CODEABLE_CONCEPT_CODE",
  "OBSERVATION",
  "OBSERVATION_INTEGER",
  "OBSERVATION_BOOLEAN",
  "OBSERVATION_DOUBLE",
  "OBSERVATION_DATE",
  "OBSERVATION_QUANTITY",
  "OBSERVATION_CODEABLE_CONCEPT_CODE"
];

export const PATIENT_DOB_DATA_FIELD = "PatientDOB";

export function getDataHolder(attributeType) {
  if (attributeType === "STRING") {
    return "valueString";
  } else if (attributeType === "INTEGER") {
    return "valueInteger";
  } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
    return "valueCode";
  } else if (attributeType === "BOOLEAN") {
    return "valueBoolean";
  } else if (attributeType === "DATE") {
    return "valueDate";
  } else if (attributeType === "HUMAN_NAME") {
    return "valueHumanName";
  } else if (attributeType === "ADDRESS") {
    return "valueAddress";
  } else if (attributeType === "CONTACT_POINT") {
    return "valueContactPoints";
  }
}

export function getObservationDataHolder(attributeType) {
  if (attributeType === "OBSERVATION") {
    return "valueString";
  } else if (attributeType === "OBSERVATION_DATE") {
    return "valueDate";
  } else if (attributeType === "OBSERVATION_INTEGER") {
    return "valueInteger";
  } else if (attributeType === "OBSERVATION_QUANTITY") {
    return "valueQuantity";
  } else if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
    return "valueCode";
  } else if (attributeType === "OBSERVATION_DOUBLE") {
    return "valueDouble";
  }
}

export function needChoiceList(attributeType) {
  return attributeType && CHOICE_LIST_TYPES.indexOf(attributeType) !== -1;
}

export function isObservation(attributeType) {
  return attributeType && OBSERVATION_TYPES.indexOf(attributeType) !== -1;
}

export function isPlaceHolder(attributeType) {
  return attributeType === "PLACEHOLDER";
}

export function getValidationHints() {
  return [
    { value: "PAST_DATE", label: "Past Date" },
    { value: "FUTURE_DATE", label: "Future Date" },
    { value: "DATE_RANGE", label: "Date Range" },
    { value: "NUMBER_RANGE", label: "Number Range" },
    { value: "PATTERN", label: "Regex Pattern" },
    { value: "PATTERN_WITH_SIZE", label: "Regex Pattern with Size" },
    { value: "EMAIL", label: "EMail" },
    { value: "PHONE_NUMBER", label: "Phone Number" },
    { value: "VALIDATE_WITH_MASTERDATA", label: "Validate with Master data" }
  ];
}

export function getValueWhenInFilter(attribute, valuePlace) {
  if (!attribute) {
    return null;
  }
  let av = attribute.value;
  let attributeType = attribute.type || attribute.attributeType;
  let value = null;

  if (!av) {
    value = null;
  } else {
    if (attributeType === "CONTACT_POINT") {
      value = ContactPoint.getDisplayValueInFilter(av);
    } else if (attributeType === "ADDRESS") {
      value = AddressAttribute.getDisplayValueInFilter(av);
    } else if (attributeType === "OBSERVATION") {
      value = StringObservation.getDisplayValue(av, valuePlace);
    } else if (attributeType === "HUMAN_NAME") {
      value = HumanNameAttribute.getDisplayValueInFilter(av);
    } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
      value = CodeableConceptCode.getDisplayValueInFilter(av);
    } else if (attributeType === "STRING") {
      value = StringAttribute.getDisplayValue(av);
    } else if (attributeType === "INTEGER") {
      value = IntegerAttribute.getDisplayValue(av);
    } else if (attributeType === "DATE") {
      if (valuePlace === "from") {
        value = av.valueFromDate;
      } else if (valuePlace === "to") {
        value = av.valueToDate;
      } else {
        value = av.valueDate;
      }
    } else if (attributeType === "POSITION") {
      return null;
    } else if (attributeType === "PLACEHOLDER") {
      value = null;
    }
  }
  if (!value) {
    return "";
  }
  return value;
}

export function getValueFieldNameForFilter(attribute, value, valuePlace) {
  let av = attribute.value || {};
  let attributeType = attribute.type || attribute.attributeType;
  let dataField = attribute.dataField;

  if (!value) {
    return null;
  }

  if (attributeType === "CONTACT_POINT") {
    if (dataField === "PatientContactPhone") {
      av = { valueContactPoints: [{ value: value, system: "PHONE" }] };
    } else if (dataField === "PatientContactEmail") {
      av = { valueContactPoints: [{ value: value, system: "EMAIL" }] };
    }
  } else if (attributeType === "DATE") {
    if (valuePlace === "from") {
      if (av) {
        av.valueFromDate = value;
      } else {
        av = { valueFromDate: value };
      }
    } else if (valuePlace === "to") {
      if (av) {
        av.valueToDate = value;
      } else {
        av = { valueToDate: value };
      }
    } else {
      if (av) {
        av.valueDate = value;
      } else {
        av = { valueDate: value };
      }
    }
  } else if (attributeType === "ADDRESS") {
    av = { valueAddress: { room: value } };
  } else if (attributeType === "POSITION") {
  } else if (attributeType === "OBSERVATION") {
    av = {
      valueObservation: { ...value }
      // valueObservation: {
      //   value: { valueString: value },
      //   code: { code: attribute.observationCode.code }
      // }
    };

    // if (valuePlace === "from") {
    //   av.valueObservation.value.valueFromString =
    //     av.valueObservation.value.valueString;
    //   delete av.valueObservation.value.valueString;
    // }
    //
    // if (valuePlace === "to") {
    //   av.valueObservation.value.valueToString =
    //     av.valueObservation.value.valueString;
    //   delete av.valueObservation.value.valueString;
    // }

    // av = {
    //   valueObservation: {
    //     value: { valueString: value },
    //     code: { code: attribute.observationCode.code }
    //   }
    // };
  } else if (attributeType === "HUMAN_NAME") {
    av = value ? { valueHumanName: { given: value } } : null;
  } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
    av = { valueCode: { code: value } };
  } else if (attributeType === "PLACEHOLDER") {
  } else if (attributeType === "STRING") {
    av = { valueString: value };
  } else if (attributeType === "INTEGER") {
    av = { valueInteger: value };
  }

  return av;
}

export function getValueForFieldsDisplay(attribute) {
  if (!attribute) {
    return null;
  }
  let av = attribute.value;
  let attributeType = attribute.attributeType || attribute.type;
  let value = null;

  if (!av) {
    value = null;
  } else {
    if (attributeType === "CONTACT_POINT") {
      value = ContactPoint.getDisplayValue(av) || null;
    } else if (attributeType === "DATE") {
      value = av.valueDate ? new Date(av.valueDate).toLocaleDateString() : null;
    } else if (attributeType === "ADDRESS") {
      value = AddressAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "POSITION") {
      value = PositionAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "OBSERVATION") {
      value = StringObservation.getDisplayValue(av) || null;
    } else if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
      value = CCCObservation.getDisplayValue(av) || null;
    } else if (attributeType === "HUMAN_NAME") {
      value = HumanNameAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "CODEABLE_CONCEPT_CODE") {
      value = CodeableConceptCode.getDisplayValue(av) || null;
    } else if (attributeType === "PLACEHOLDER") {
      value = PlaceholderAttribute.getDisplayValue(av);
    } else if (attributeType === "STRING") {
      value = StringAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "INTEGER") {
      value = IntegerAttribute.getDisplayValue(av) || null;
    } else if (attributeType === "OBSERVATION_DATE") {
      value = DateObservation.getDisplayValue(av) || null;
    }
  }
  return value;
}

export function generateUiAttributeAddressText({
  room,
  floor,
  line,
  city,
  district,
  state,
  postalCode,
  country
}) {
  let textBuilder = "";
  if (room) textBuilder += room + " ";
  if (floor) textBuilder += floor + " ";
  if (line) {
    line.forEach(addLine => {
      textBuilder += addLine + " ";
    });
  }

  if (city) textBuilder += city + " ";
  if (district) textBuilder += district + " ";
  if (state) textBuilder += state + " ";
  if (postalCode) textBuilder += postalCode + " ";
  if (country) textBuilder += country;
  return textBuilder;
}

export function generateUIAttributeNameText({ prefix, given, family, suffix }) {
  let nameText = "";
  if (prefix) {
    nameText += prefix + " ";
  }
  if (given) {
    nameText += given + " ";
  }
  if (family) {
    nameText += family + " ";
  }
  if (suffix) {
    nameText += suffix;
  }
  return nameText;
}

export function shouldShowMasterDataSelector(type) {
  return (
    type === "STRING" ||
    type === "CODEABLE_CONCEPT_CODE" ||
    type === "OBSERVATION" ||
    type === "OBSERVATION_CODEABLE_CONCEPT_CODE"
  );
}

export const ATTRIBUTES_FOR_CARE_PLAN_CRITERIA = [
  "BOOLEAN",
  "STRING",
  "INTEGER",
  "DATE",
  "CODEABLE_CONCEPT_CODE",
  "OBSERVATION"
];

export function getAttributeOptionsChoiceList(attributeType, options) {
  if (attributeType === "CODEABLE_CONCEPT_CODE") {
    return CodeableConceptCode.getOptionsList(options);
  }
  if (attributeType === "OBSERVATION") {
    return StringObservation.getOptionsList(options);
  }
  if (attributeType === "OBSERVATION_CODEABLE_CONCEPT_CODE") {
    return CCCObservation.getOptionsList(options);
  }
}
