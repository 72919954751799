import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row
} from "reactstrap";

export default class SignupFacilitySelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.inputEl) {
      this.inputEl.focus();
    }
  }

  submitForm = e => {
    e.preventDefault();
    let { facilityCode } = this.state;
    if (facilityCode) {
      this.props.submitCode(facilityCode);
    }
  };

  render() {
    let { facilityCode } = this.state;
    let { facilityErrorMessage, facilityError } = this.props;
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" sm={12}>
              <div className="ob-logos mb-3 justify-content-center my-4">
                <div className="">
                  <img
                    alt="myPatientSpace"
                    src="/img/mps-logo.png"
                    height={32}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col md="6" sm={12}>
              <CardGroup>
                <Card>
                  <CardBody>
                    <form
                      className="d-flex flex-column"
                      onSubmit={this.submitForm}
                    >
                      <h5 className="mt-3 text-center">
                        myPatientSpace is Invite-Only
                      </h5>

                      <div className="my-3" style={{ fontSize: "16px" }}>
                        To use myPatientSpace, you need to create an account.
                        Start by entering a code given by your Health Care
                        Provider.
                      </div>

                      {facilityError && (
                        <div>
                          <p className="text-muted">
                            <span style={{ color: "red" }}>
                              {facilityErrorMessage ===
                              "No facility information found for the code given"
                                ? "Invalid Code. Please try again."
                                : facilityErrorMessage}
                            </span>
                          </p>
                        </div>
                      )}

                      <input
                        style={{
                          height: "42px",
                          textAlign: "center",
                          fontSize: "20px",
                          letterSpacing: "3px",
                          borderWidth: "2px"
                        }}
                        ref={attrEl => {
                          this.inputEl = attrEl;
                        }}
                        placeholder={"Code"}
                        className="form-control"
                        type="text"
                        value={facilityCode}
                        onChange={e => {
                          this.setState({ facilityCode: e.target.value });
                        }}
                        autoComplete="nope"
                        onBlur={this.handleUserNameOnBlur}
                      />

                      <div className="d-flex mt-3">
                        <Button
                          size="lg"
                          color="primary"
                          className="px-4"
                          disabled={!facilityCode}
                          style={{
                            cursor: !facilityCode ? "default" : "pointer",
                            width: "100%"
                          }}
                        >
                          Go
                        </Button>
                      </div>
                    </form>

                    <div className="mt-5" style={{ fontSize: "13px" }}>
                      <p>
                        If you were asked to use myPatientSpace but don't have a
                        hospital code, please contact your provider. The code
                        would have been provided to you at the hospital, or on a
                        printed leaflet.
                      </p>
                      <p>
                        If you've been sent an invite that you received via
                        Email or SMS text, please click the link there.
                      </p>
                      <p>
                        If you believe you've entered the code correctly but are
                        seeing an error, please contact{" "}
                        <a href="mailto:support@mypatientspace.com">
                          support@mypatientspace.com
                        </a>
                        .
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
