import React from "react";

export default () => (
  <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1">
    <title>Combined Shape</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g
      id="4.0-Home-&amp;-Statuss"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Group-6" fill="#6E7C99">
        <path
          d="M7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 Z M10.2196699,4.71966991 L6.25,8.68933983 L4.78033009,7.21966991 C4.48743687,6.9267767 4.01256313,6.9267767 3.71966991,7.21966991 C3.4267767,7.51256313 3.4267767,7.98743687 3.71966991,8.28033009 L6.25,10.8106602 L11.2803301,5.78033009 C11.5732233,5.48743687 11.5732233,5.01256313 11.2803301,4.71966991 C10.9874369,4.4267767 10.5125631,4.4267767 10.2196699,4.71966991 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);
