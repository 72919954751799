import React from "react";
import classnames from "classnames";
import { validatePassword, validateUserName } from "../../utils/utils";
import userService from "../../services/userService";
import Mandatory from "../../Shared/Packages/UiAttributes/Onboarding/Mandatory";

export default class SignUpCredentialsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { formErrors: {} };
  }

  validate = () => {
    const { username, password, confirmPassword, formErrors } = this.state;
    let usernameNotUnique = formErrors.usernameNotUnique;
    let nfe = {};
    if (usernameNotUnique) {
      nfe.usernameNotUnique = usernameNotUnique;
    } else if (!validateUserName(username)) {
      nfe.username = true;
    }
    if (!validatePassword(password)) {
      nfe.password = true;
    }
    if (confirmPassword !== password) {
      nfe.confirmPassword = true;
    }
    this.setState({ formErrors: nfe });
    return Object.keys(nfe).length < 1;
  };

  handleUserNameOnBlur = () => {
    const { username, formErrors } = this.state;
    let valid = validateUserName(username);
    formErrors.username = !valid;
    formErrors.usernameNotUnique = false;
    this.setState({ formErrors: { ...formErrors } });
    if (!valid) {
      return;
    }
    let validationData = {
      facilityCode: this.props.facilityCode,
      validationFields: ["USERNAME"],
      value: username
    };
    userService.validateSignUpField(validationData).then(res => {
      let response = res[0];
      if (!response) {
        formErrors.usernameNotUnique = false;
        this.setState({ formErrors: { ...formErrors } });
        return;
      }
      if (!response.success && response.code === "USERNAME_NOT_UNIQUE") {
        formErrors.usernameNotUnique = true;
        this.setState({ formErrors: { ...formErrors } });
      }
    });
  };

  handlePasswordOnBlur = () => {
    const { password, formErrors } = this.state;
    formErrors.password = !validatePassword(password);
    this.setState({ formErrors: { ...formErrors } });
  };

  handleConfirmPasswordOnBlur = () => {
    const { confirmPassword, password, formErrors } = this.state;
    formErrors.confirmPassword = confirmPassword !== password;
    this.setState({ formErrors: { ...formErrors } });
  };

  getExistingUserIdMessage = () => {
    let { loginUserIdAttrs } = this.props;
    let prefix = "";
    if (loginUserIdAttrs.email && loginUserIdAttrs.phone) {
      prefix = `Email address and Phone number are`;
    } else if (loginUserIdAttrs.email) {
      prefix = `Email address is`;
    } else if (loginUserIdAttrs.phone) {
      prefix = `Phone number is`;
    }

    return `${prefix} already associated with an account. You can continue and you will be added to another space, or you can use a different email or phone number to create a new account`;
  };

  render() {
    let {
      username,
      password,
      confirmPassword,
      existingLoginUserId
    } = this.props;

    if (existingLoginUserId) {
      return (
        <div>
          <div className="row">
            <div
              className="col-12 my-1 pb-2"
              style={{ backgroundColor: "#fff" }}
            >
              {this.getExistingUserIdMessage()}
            </div>
          </div>
        </div>
      );
    }

    let { formErrors } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-12 my-1 pb-2" style={{ backgroundColor: "#fff" }}>
            <div className="d-flex flex-column">
              <input
                placeholder={"Username"}
                className={classnames(
                  formErrors.username || formErrors.usernameNotUnique
                    ? "is-invalid"
                    : "",
                  "form-control"
                )}
                type="search"
                value={username}
                onChange={e => {
                  this.setState({ username: e.target.value });
                  this.props.setCredentialsData("username", e.target.value);
                }}
                autoComplete="nope"
                onBlur={this.handleUserNameOnBlur}
              />
              <Mandatory mandatory />
              <small>
                Either Valid Email or Only letters, numbers and underline are
                allowed
              </small>
              <div className="invalid-feedback">
                {formErrors.username && <span>Please set valid username.</span>}
                {formErrors.usernameNotUnique && (
                  <span>Mentioned Username is already taken.</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 my-1 pb-2" style={{ backgroundColor: "#fff" }}>
            <div className="d-flex flex-column">
              <input
                placeholder={"Password"}
                className={classnames(
                  formErrors.password ? "is-invalid" : "",
                  "form-control"
                )}
                type="password"
                value={password}
                onChange={e => {
                  this.setState({ password: e.target.value });
                  this.props.setCredentialsData("password", e.target.value);
                }}
                autoComplete="new-password"
                onBlur={this.handlePasswordOnBlur}
              />
              <Mandatory mandatory />
              <small>
                Minimum 6 characters with 1 lowercase & 1 uppercase letter, 1
                number, and no whitespaces.
              </small>
              <div className="invalid-feedback">Please set valid password.</div>
            </div>
          </div>
          <div className="col-12 my-1 pb-2" style={{ backgroundColor: "#fff" }}>
            <div className="d-flex flex-column">
              <input
                placeholder={"Confirm Password"}
                className={classnames(
                  formErrors.confirmPassword ? "is-invalid" : "",
                  "form-control"
                )}
                type="password"
                value={confirmPassword}
                onChange={e => {
                  this.setState({ confirmPassword: e.target.value });
                  this.props.setCredentialsData(
                    "confirmPassword",
                    e.target.value
                  );
                }}
                autoComplete="new-password"
                onBlur={this.handleConfirmPasswordOnBlur}
              />
              <Mandatory mandatory />
              <div className="invalid-feedback">
                Repeated password must be same as password.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
