import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import { isPatientOrMember } from "../security/roles";

class ModalPopup extends Component {
  render() {
    let {
      title,
      children,
      onClose,
      size = "lg",
      keyboard = true,
      backdrop = "static",
      className,
      patientApp
    } = this.props;

    return (
      <Modal
        isOpen={true}
        toggle={onClose}
        size={size}
        keyboard={keyboard}
        backdrop={backdrop}
        className={className}
      >
        <ModalHeader
          className={patientApp ? "patient-model-header" : null}
          toggle={onClose}
        >
          {title}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let userRole = state.user.role;

  return {
    patientApp: isPatientOrMember(userRole)
  };
};

export default connect(mapStateToProps)(ModalPopup);
