// @flow

import {
  LOAD_ORGANIZATIONS,
  ORGANIZATIONS_LOADED,
  ORGANIZATION_UPDATE,
  ORGANIZATION_UPDATE_DONE,
  ORGANIZATION_ADD,
  ORGANIZATION_ADD_DONE,
  ORGANIZATIONS_RESET_LOADING,
  ORGANIZATION_UPDATE_BRANDING,
  ORGANIZATION_UPDATE_BRANDING_DONE,
  LOG_OUT,
  LOAD_ORGANIZATION_DONE,
  LOAD_ORGANIZATION
} from "../actions/actionTypes";
import { findWithAttr } from "../../utils/utils";
import { REHYDRATE } from "redux-persist";

const initialState = {
  loading: false,
  // last page loaded
  page: 0,
  // is there need to load more, currently determined
  // to be false if last page listing returned no results
  loadMore: true,
  list: []
};

const organizationsReducer = (state = initialState, action) => {
  let list, index;

  switch (action.type) {
    case ORGANIZATIONS_RESET_LOADING:
      return initialState;

    case LOAD_ORGANIZATIONS:
      return { ...state, loading: true };

    case ORGANIZATION_UPDATE:
    case ORGANIZATION_UPDATE_BRANDING:
    case ORGANIZATION_ADD:
    case LOAD_ORGANIZATION:
      return { ...state, loading: true };

    case LOAD_ORGANIZATION_DONE:
      return { ...state, loading: false, organizationSingle: action.data };

    case ORGANIZATION_UPDATE_DONE:
    case ORGANIZATION_ADD_DONE:
    case ORGANIZATION_UPDATE_BRANDING_DONE:
      // console.log('UPDATE DATA ', action, state.list);
      list = state.list.slice(0);
      index = findWithAttr(list, "id", action.data.id);
      // console.log('INDEX FOUND ', index);
      // not found then return prev state
      if (index === -1) {
        return {
          ...state,
          loading: false,
          organizationSingle: null,
          list: list.concat([action.data])
        };
      } else {
        // if found, replace whole object
        list[index] = action.data;
      }

      return {
        ...state,
        loading: false,
        organizationSingle: undefined,
        list: list
      };

    case ORGANIZATIONS_LOADED:
      // console.log('ORGTEST', action);
      const newState = {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        list:
          action.page === 1
            ? action.list
            : state.list.slice(0).concat(action.list)
      };
      // console.log('REDUCER ORGANIZATIONS ', newState);
      return newState;

    case LOG_OUT:
      return initialState;

    case REHYDRATE:
      if (action.payload && action.payload.organizations) {
        return { ...state, ...action.payload.organizations, loading: false };
      }
      return state;

    default:
      return state;
  }
};

export default organizationsReducer;
