import React from "react";
import BackgroundImageUrl from "./index";
import { Thumbs } from "../../Shared/Statuses";

class BackgroundImagesWithThumbs extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currMedia: 0
    };
  }

  onThumbPress = index => {
    this.setState({
      currMedia: index
    });
  };

  render() {
    let { contents, id } = this.props;
    const { currMedia } = this.state;

    return (
      <div className="media">
        <BackgroundImageUrl
          key={currMedia}
          className="fullSize"
          url={contents[currMedia].contentURL}
        />

        <div className="thumbsHolder">
          <Thumbs
            id={id}
            content={contents}
            active={currMedia}
            onClick={this.onThumbPress}
          />
        </div>
      </div>
    );
  }
}

export default BackgroundImagesWithThumbs;
