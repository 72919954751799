import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import Select from "react-select";

class CodeableConceptCode extends Component {
  static getDisplayValue(av) {
    return av && av.valueCode ? av.valueCode.display : null;
  }

  static getDisplayValueInFilter(av) {
    return av.valueCode ? av.valueCode.code : null;
  }

  static getOptionsList(options) {
    return options.map(option => {
      return {
        value: option.valueCode.code,
        label: option.valueCode.display
      };
    });
  }

  render() {
    let { attributeValue, choiceList, options, setData } = this.props;
    attributeValue.valueCode = attributeValue.valueCode || {};
    if (choiceList) {
      return (
        <FormGroup>
          <div className="inputHolder flex-column">
            <div>Selected Value: &nbsp; {attributeValue.valueCode.display}</div>

            <Select
              name="form-field-name"
              value={attributeValue.valueCode}
              onChange={option => {
                if (option) {
                  attributeValue.valueCode = option.value;
                } else {
                  attributeValue.valueCode = {};
                }
                setData(attributeValue);
              }}
              options={options.map(option => {
                return {
                  value: option.valueCode,
                  label: option.valueCode.display
                };
              })}
            />
          </div>
        </FormGroup>
      );
    } else {
      let cccCode = attributeValue.valueCode.code;
      let cccVal = attributeValue.valueCode.display;
      return (
        <FormGroup>
          <div className="inputHolder flex-row">
            <Input
              className="mr-2"
              placeholder="Code"
              value={cccCode}
              onChange={e => {
                attributeValue.valueCode.display = e.target.value;
                setData(attributeValue);
              }}
              type="text"
            />
            <Input
              placeholder="Value"
              value={cccVal}
              onChange={e => {
                attributeValue.valueCode.display = e.target.value;
                setData(attributeValue);
              }}
              type="text"
            />
          </div>
        </FormGroup>
      );
    }
  }
}

export default CodeableConceptCode;
