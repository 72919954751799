import { createIntl, createIntlCache } from "react-intl";
import messagesEn from "./../Locales/en.json";
import messagesAr from "./../Locales/ar.json";
// import { IntlProvider, addLocaleData } from "react-intl";
// import localeEn from "react-intl/locale-data/en";
// addLocaleData([...localeEn]);
// const locale = "fr";
const messages = {
  en: messagesEn,
  ar: messagesAr
};
// const intlProvider = new IntlProvider({ locale, messages });
// const { intl } = intlProvider.getChildContext();
//
// export default intl;

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();
const intl = createIntl(
  {
    locale: "en",
    messages: messages.ar
  },
  cache
);

// Call imperatively
// intl.formatNumber(20)

export default intl;
