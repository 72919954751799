import React from "react";
import MYPTermsAndConditions from "./MYPTermsAndConditions";
import ArticlePreview from "../../Shared/ContentLibrary/Preview/ArticlePreview";
import libraryService from "../../services/contentLibraryService";
import MPSBarLoader from "../../components/MPSBarLoader";

export default class TermsAndConditions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.setState({ loading: true });
    if (!this.props.termsAndConditionsLibrary) {
      return;
    }
    libraryService
      .loadLibraryItemForSignup(this.props.termsAndConditionsLibrary)
      .then(response => {
        this.setState({ terms: response, loading: false });
      })
      .catch(e => this.setState({ loading: false }));
  }

  render() {
    let { termsAndConditionsLibrary } = this.props;

    if (!termsAndConditionsLibrary) {
      return <MYPTermsAndConditions />;
    }

    let { loading, terms } = this.state;
    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    return <ArticlePreview article={terms} embed />;
  }
}
