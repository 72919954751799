import React, { Component } from "react";
import MPSButton from "../../../../components/Buttons/Buttons/MPSButton";

class PlaceholderAttribute extends Component {
  static getDisplayValueAsLink(attribute, title, showPlaceholder) {
    let type = null;
    if (attribute.name === "MPS_SPACE_MEMBERS") {
      type = "members";
    } else if (attribute.name === "GET_ALL_PATIENT_DOCUMENTS") {
      type = "documents";
    } else if (attribute.name === "GET_ALL_ASSIGNED_DEVICES") {
      type = "devices";
    }
    if (type) {
      return (
        <MPSButton
          type="link"
          onClick={() => {
            showPlaceholder(type, title);
          }}
        >
          View
        </MPSButton>
      );
    } else {
      return null;
    }
  }

  static getDisplayValue(av) {
    return av.valueCode ? av.valueCode.display : null;
  }

  render() {
    return null;
  }
}

export default PlaceholderAttribute;
