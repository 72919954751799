import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import { generateUiAttributeAddressText } from "../Utils";

class AddressAttribute extends Component {
  static getDisplayValue(attributeValue) {
    return attributeValue.valueAddress
      ? generateUiAttributeAddressText(attributeValue.valueAddress)
      : null;
  }

  static getDisplayValueText(attributeValue) {
    return attributeValue.valueAddress
      ? attributeValue.valueAddress.text
      : null;
  }

  static getDisplayValueInFilter(av) {
    return av.valueAddress ? av.valueAddress.room : null;
  }

  render() {
    let { attributeValue, setData } = this.props;

    attributeValue.valueAddress = attributeValue.valueAddress || {};
    let addressVal = attributeValue.valueAddress;
    return (
      <div>
        <FormGroup>
          <Input
            className="mr-2 my-1"
            value={addressVal.room}
            onChange={e => {
              attributeValue.valueAddress.room = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="Room"
          />

          <Input
            className="my-1"
            value={addressVal.floor}
            onChange={e => {
              attributeValue.valueAddress.floor = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="Floor"
          />
        </FormGroup>
        <FormGroup>
          <Input
            className="mr-2 my-1"
            value={addressVal.line ? addressVal.line[0] : ""}
            onChange={e => {
              attributeValue.valueAddress.line =
                attributeValue.valueAddress.line || [];
              attributeValue.valueAddress.line[0] = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="Address Line 1"
          />

          <Input
            className="my-1"
            value={addressVal.line ? addressVal.line[1] : ""}
            onChange={e => {
              attributeValue.valueAddress.line =
                attributeValue.valueAddress.line || [];
              attributeValue.valueAddress.line[1] = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="Address Line 2"
          />
        </FormGroup>
        <FormGroup>
          <Input
            className="mr-2 my-1"
            value={addressVal.city}
            onChange={e => {
              attributeValue.valueAddress.city = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="City"
          />

          <Input
            className="mr-2 my-1"
            value={addressVal.county}
            onChange={e => {
              attributeValue.valueAddress.county = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="County"
          />

          <Input
            className="mr-2 my-1"
            value={addressVal.district}
            onChange={e => {
              attributeValue.valueAddress.district = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="District"
          />
        </FormGroup>

        <FormGroup>
          <Input
            className="mr-2 my-1"
            value={addressVal.state}
            onChange={e => {
              attributeValue.valueAddress.state = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="State"
          />

          <Input
            className="mr-2 my-1"
            value={addressVal.postalCode}
            onChange={e => {
              attributeValue.valueAddress.postalCode = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="Postal Code"
          />

          <Input
            className="my-1"
            value={addressVal.country}
            onChange={e => {
              attributeValue.valueAddress.country = e.target.value;
              setData(attributeValue);
            }}
            type="text"
            placeholder="Country"
          />
        </FormGroup>
      </div>
    );
  }
}

export default AddressAttribute;
