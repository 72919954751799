import { http } from "../utils/http";
import { API_ENDPOINTS, PAGE_SIZE } from "../config";
import { store } from "../State/store/configureStore";
import { isStaff, ROLE_FACILITY_ADMIN } from "../security/roles";

class StaffService {
  static delete(staffId) {
    return http.request(API_ENDPOINTS.deleteStaff(staffId), "delete");
  }

  static invite(data, activeInternal) {
    let url = API_ENDPOINTS.inviteStaff(
      data.hasOwnProperty("loginUserId") || activeInternal
    );
    if (activeInternal) {
      url += "?makeActiveInternal=true";
    }
    return http.request(url, "post", data);
  }

  static load(page, all, showInactive) {
    let api = null;
    let role = store.getState().user.role;
    if (isStaff(role)) {
      api = API_ENDPOINTS.loadAllStaffForStaff();
    } else {
      api = API_ENDPOINTS.loadStaff();
    }

    let pageSize = PAGE_SIZE;
    if (all) {
      pageSize = 1000;
    }

    api = api + `?page=${page}&size=${pageSize}`;
    if (showInactive) {
      api += "&allStatus=true";
    }

    return http.request(api).then(data => {
      if (data && data._embedded) {
        return {
          data: data._embedded.staffs || [],
          loadMore: data.page.totalPages > page + 1,
          totalStaff: data.page.totalElements
        };
      } else {
        return {
          data: [],
          loadMore: false,
          totalStaff: 0
        };
      }
    });
  }

  static loadConsultants() {
    return http.request(API_ENDPOINTS.consultants).then(data => {
      return data && data._embedded ? data._embedded.staffWithRoles : [];
    });
  }

  static loadOneStaff(staffId) {
    return http.request(API_ENDPOINTS.loadOneStaff(staffId));
  }

  static loadProfile() {
    return http.request(API_ENDPOINTS.loadStaffProfile);
  }

  static editProfile(data) {
    return http.request(API_ENDPOINTS.loadStaffProfile, "patch", data);
  }

  static createCareTeam(data) {
    return http.request(API_ENDPOINTS.careTeams, "post", data);
  }

  static editCareTeam(careTeamId, data) {
    return http.request(
      API_ENDPOINTS.careTeams + "/" + careTeamId,
      "patch",
      data
    );
  }

  static deleteCareTeam(careTeamId) {
    return http.request(API_ENDPOINTS.careTeams + "/" + careTeamId, "delete");
  }

  static getCareTeams(data) {
    return http.request(API_ENDPOINTS.careTeams).then(data => {
      if (data && data._embedded) {
        return data._embedded.careTeams;
      } else {
        return [];
      }
    });
  }

  static editStaff(staffId, data) {
    return http.request(API_ENDPOINTS.editStaff(staffId), "patch", data);
  }

  static reinviteStaff(staffId, data) {
    return http.request(API_ENDPOINTS.reInviteStaff(staffId), "post", data);
  }

  static getStaffLocations() {
    return http.request(API_ENDPOINTS.allResmedLocations).then(data => {
      // {"Response":{"totalCount":"1","locations":[{"name":"PEI - MPS","id":"4783"}]}}
      return data && data.Response ? data.Response.locations || [] : [];
    });
  }

  static getClinicalUSerNames(locId) {
    return http.request(API_ENDPOINTS.resmedUsers(locId)).then(data => {
      return data && data.Response ? data.Response.clinicalUsers || [] : [];
    });
  }

  static restoreStaff(staffId) {
    return http.request(API_ENDPOINTS.restoreStaff(staffId), "post");
  }

  static resetPassword(staffId, loginUserId, newPassword) {
    return http.request(API_ENDPOINTS.resetStaffPassword(staffId), "post", {
      loginUserId,
      password: newPassword
    });
  }
}

const staffService = StaffService;

export default staffService;
