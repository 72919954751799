// @flow

import {
  DISMISS_ERROR,
  LOAD_SURVEYS,
  LOG_OUT,
  RESET_CREATE_SURVEY,
  SURVEYS_LOADED,
  SURVEYS_RESET_LOADING,
  SURVEY_ADD,
  SURVEY_ADD_DONE,
  SURVEY_ADD_ERROR,
  UPDATE_CREATE_SURVEY
} from "../actions/actionTypes";

import { REHYDRATE } from "redux-persist/es/constants";

export function initialQuestions() {
  return [
    // first questions, it's treated like group 1
    {
      linkId: "group0",
      text: "",
      type: "GROUP",
      items: [
        {
          id: "question0",
          prefix: "",
          text: "",
          required: false,
          type: "STRING",
          enableWhen: null,
          initialValue: null
        }
      ]
    }
  ];
}

const initialState = {
  tab: "general",
  loading: false,
  loadMore: true,
  page: 0,
  list: [],
  error: false,
  errorMsg: "",
  create: {
    language: {
      code: "http://hl7.org/fhir/ValueSet/languages",
      display: "en"
    },
    //createdOn: 1525324055423,
    //lastUpdatedOn: 1525324055423,
    //createdByUser: '5adf9cc669913f0004af401d',
    //modifiedByUser: '5adf9cc669913f0004af401d',
    //identifier: null,
    //jurisdiction: null,
    // isContentLocked: false,
    patientTypeId: null,
    // patientTypeIds: [],
    patientTypeText: null,
    name: "",
    title: "",
    tab: "general",
    description: "",
    status: "DRAFT", // draft | active | retired | unknown
    isChartingEnabled: false,
    // saveScoreOutsidePatientModel: false,
    facilityId: "",
    resourceType: "Questionnaire",
    effectivePeriodStartDate: null,
    effectivePeriodEndDate: null,
    subjectType: ["PATIENT"],
    items: initialQuestions().slice(0),
    // items start with one group, if more added this reflects current group being edited
    currItemGroup: 0
  }
};

const surveysReducer = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && action.payload.surveys) {
        if (!action.payload.surveys.hasOwnProperty("create")) {
          console.log(
            "SURVEY REHYDRATE + INIT CREATE ",
            ...state,
            action.payload.surveys
          );
          return {
            ...action.payload.surveys,
            create: initialState.create
          };
        } else {
          console.log("SURVEY REHYDRATE ALL ", action.payload.surveys);
          return { ...state, create: action.payload.surveys.create };
        }
      }
      // console.log('ACTION PAYLOAD SURVEYS', action.payload.surveys);
      return state;

    case SURVEYS_RESET_LOADING:
    case SURVEY_ADD_DONE:
      return initialState;

    case LOAD_SURVEYS:
    case SURVEY_ADD:
      return { ...state, loading: true };

    case SURVEY_ADD_ERROR:
      return { ...state, error: true, loading: false, errorMsg: action.error };

    case DISMISS_ERROR:
      return { ...state, error: false, errorMsg: "" };

    case SURVEYS_LOADED:
      // console.log('ORGTEST', action);
      const newState = {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        list:
          action.page === 1
            ? action.list
            : state.list.slice(0).concat(action.list)
      };
      // console.log('REDUCER SURVEYS ', newState);
      return newState;

    case UPDATE_CREATE_SURVEY:
      console.log("UPDATE CREATE ", action);
      console.log("NEW STATE ", {
        ...state,
        create: { ...state.create, ...action.values }
      });
      return { ...state, create: { ...state.create, ...action.values } };

    case RESET_CREATE_SURVEY:
      return { ...state, create: initialState.create };

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default surveysReducer;
