import React, { PureComponent } from "react";
import { getValueWhenInFilter } from "../../Shared/Packages/UiAttributes/Utils";
import DateAttribute from "../../Shared/Packages/UiAttributes/Onboarding/DateAttribute";
import OptionsObservation from "../../Shared/Packages/UiAttributes/Onboarding/OptionsObservation";
import TextObservation from "../../Shared/Packages/UiAttributes/Onboarding/TextObservation";
import PhoneAttribute from "../../Shared/Packages/UiAttributes/Onboarding/PhoneAttribute";
import AttributeWithOptions from "../../Shared/Packages/UiAttributes/Onboarding/AttributeWithOptions";
import NameAttribute from "../../Shared/Packages/UiAttributes/Onboarding/NameAttribute";
import TextAttribute from "../../Shared/Packages/UiAttributes/Onboarding/TextAttribute";
import { validateEmail } from "../../utils/utils";
import { doE164, validatePhoneNumber } from "../../utils/phone";
import userService from "../../services/userService";
import moment from "moment";

function getValidationMessage(code, message, field) {
  if (code === "USERNAME_NOT_UNIQUE") {
    return "This Username is already taken.";
  }
  if (code === "USER_HAS_USERNAME") {
    return `This ${field} is already associated with an account.`;
  }
  if (code === "INVALID_CODE") {
    return message || `This ${field} is not valid`;
  }

  if (code === "CODE_NOT_UNIQUE") {
    return `This ${field} is already used.`;
  }
  return null;
}

export function getOnboardingAttributeValue(attribute) {
  let value = null;
  let aType = attribute.type || attribute.attributeType;
  if (aType === "DATE") {
    if (attribute.value) {
      value = attribute.value.valueString;
    }
  } else {
    value = getValueWhenInFilter(attribute);
  }
  return value;
}

class UserOnboardingChallengeInvite extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderFields = attributes => {
    let placeholder = this.props.referrer === "SELF_SIGNUP";
    return attributes.map((attribute, index) => {
      let type = attribute.type || attribute.attributeType;
      let Element = null;
      if (attribute.hidden) {
        return null;
      }
      if (type === "OBSERVATION") {
        if (attribute.options && attribute.options.length > 0) {
          Element = OptionsObservation;
        } else {
          Element = TextObservation;
        }
      } else {
        if (attribute.options) {
          Element = AttributeWithOptions;
        } else {
          if (type === "DATE") {
            Element = DateAttribute;
          } else if (
              attribute.dataField === "PatientContactPhone" ||
              attribute.dataField === "PatientContactTel"
          ) {
            Element = PhoneAttribute;
          } else if (
              attribute.dataField === "PatientName" &&
              this.props.referrer === "SELF_SIGNUP"
          ) {
            Element = NameAttribute;
          } else {
            Element = TextAttribute;
          }
        }
      }
      return (
          <Element
              key={index}
              setAttributeRef={this.props.setAttributeRef}
              placeholder={placeholder}
              attribute={attribute}
              index={index}
              handleOnBlur={this.handleOnBlur}
              changeField={this.changeField}
              error={this.props.error}
          />
      );
    });
  };

  handleOnBlur = (index, attribute) => {
    if (attribute.hidden) {
      return;
    }

    delete attribute.error;
    delete attribute.info;
    delete attribute.validationCode;
    this.changeField(index, attribute);

    let value = getOnboardingAttributeValue(attribute);
    if (this.props.referrer !== "SELF_SIGNUP") {
      if (attribute.mandatory && !value) {
        attribute.error = attribute.title + " is required.";
        this.changeField(index, attribute);
      }
      return;
    }

    if (attribute.mandatory && !value) {
      attribute.error = attribute.title + " is required.";
      this.changeField(index, attribute);
      return;
    }

    if (attribute.name === "ConfirmEmail") {
      if (value.toLowerCase() !== this.email.toLowerCase()) {
        attribute.error = "Email and Confirm email should be the same.";
        this.changeField(index, attribute);
        return;
      }
    }

    if (attribute.name === "ConfirmPhone") {
      let newAV = JSON.parse(JSON.stringify(attribute.value));
      let phone = newAV.valueContactPoints[0].value;
      let cc = newAV.valueContactPoints[0].countryCode;
      if (phone !== this.phone.value || cc !== this.phone.countryCode) {
        attribute.error = "Phone and Confirm Phone should be the same.";
        this.changeField(index, attribute);
        return;
      }
    }

    let validation = attribute.attributeValidation;

    if (validation) {
      let hint = validation.validationHint;
      if (hint === "PAST_DATE" && value) {
        let dateDiff = moment()
            .startOf("day")
            .diff(moment(value), "days");
        if (dateDiff < 0) {
          attribute.error = attribute.title + " should be a past date";
          this.changeField(index, attribute);
        }
      } else if (hint === "FUTURE_DATE" && value) {
        let dateDiff = moment()
            .startOf("day")
            .diff(moment(value), "days");
        if (dateDiff > 0) {
          attribute.error = attribute.title + " should be a future date";
          this.changeField(index, attribute);
        }
      } else if (hint === "VALIDATE_WITH_MASTERDATA") {
        this.validateExistence(index, attribute, "MASTERDATA_UNIQUE");
      }
    }

    if (
        attribute.dataField === "PatientContactEmail" &&
        attribute.name !== "ConfirmEmail"
    ) {
      if (value) {
        if (!validateEmail(value)) {
          attribute.error = "Please provide a valid email";
          this.changeField(index, attribute);
        } else {
          this.email = value;
          this.validateExistence(index, attribute, "EMAIL");
        }
      } else {
        this.props.onBlurForConfirmFields(
            attribute,
            index,
            this.props.sectionIndex,
            "EMAIL"
        );
      }
    }

    if (
        attribute.dataField === "PatientContactPhone" &&
        attribute.name !== "ConfirmPhone"
    ) {
      if (value) {
        let newAV = JSON.parse(JSON.stringify(attribute.value));
        let phone = newAV.valueContactPoints[0].value;
        let cc = newAV.valueContactPoints[0].countryCode;
        if (
            !phone ||
            (phone && (!cc || !validatePhoneNumber(cc + " " + phone)))
        ) {
          attribute.error = "Please provide a valid phone with country code";
          this.changeField(index, attribute);
        } else {
          this.phone = { countryCode: cc, value: phone };
          this.validateExistence(index, attribute, "PHONE", doE164(cc, phone));
        }
      } else {
        this.props.onBlurForConfirmFields(
            attribute,
            index,
            this.props.sectionIndex
        );
      }
    }
  };

  validateExistence = (index, attribute, validationField, argValue) => {
    let value = argValue || getOnboardingAttributeValue(attribute);
    if (!value) {
      return;
    }
    let description = attribute.description || attribute.title;
    let validationData = {
      facilityCode: this.props.facilityCode,
      validationFields: [validationField],
      value
    };
    if (validationField === "MASTERDATA_UNIQUE") {
      validationData.attributeName = attribute.name;
    }
    userService.validateSignUpField(validationData).then(res => {
      let response = res[0];
      if (!response) {
        delete attribute.validationCode;
        delete attribute.error;
        delete attribute.info;
        if (validationField === "EMAIL" || validationField === "PHONE") {
          this.props.onBlurForConfirmFields(
              attribute,
              index,
              this.props.sectionIndex,
              validationField
          );
        }
        this.changeField(index, attribute);
        return;
      }

      if (response.code) {
        attribute.validationCode = response.code;
      } else {
        delete attribute.validationCode;
      }
      if (!response.success) {
        attribute.error = getValidationMessage(
            response.code,
            response.message,
            description
        );
      } else {
        attribute.info = getValidationMessage(
            response.code,
            response.message,
            description
        );
      }
      this.changeField(index, attribute);
    });
  };

  changeField = (index, attribute) => {
    let { challengeFields, onUpdateData } = this.props;
    challengeFields[index] = { ...attribute };
    onUpdateData("challengeFields", [...challengeFields]);
  };

  render() {
    const { challengeFields = [] } = this.props;

    return (
        <div>
          <div className="row">{this.renderFields(challengeFields)}</div>
        </div>
    );
  }
}

export default UserOnboardingChallengeInvite;
