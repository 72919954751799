import "regenerator-runtime/runtime";

// Redux Store Configuration
import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import { composeWithDevTools } from "remote-redux-devtools";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers/index";
import rootSaga from "../sagas/index";
// import storage from "redux-persist/lib/storage";
import storage from "redux-persist/lib/storage/session";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({ suppressConnectErrors: false });
const middleware = applyMiddleware(sagaMiddleware);

const persistConfig = {
  key: "root",
  blacklist: [
    "patients",
    "organizations",
    "organizationAdmins",
    "staff",
    "statuses",
    "patientTypes",
    "contentLibrary",
    "toaster",
    "uploadProgress",
    "masterData"
  ],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const initialState = {};

export const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(middleware)
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
// persistor.purge();
