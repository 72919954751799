import React, { PureComponent } from "react";
import fileService from "../../services/fileService";
import MPSBarLoader from "../MPSBarLoader";

class PDFViewer extends PureComponent {
  state = {};
  componentDidMount() {
    this.setState({ loading: true });
    fileService
      .getFile(this.props.url)
      .then(data => {
        this.setState({ dataUrl: data, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading, dataUrl } = this.state;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    return (
      <embed
        src={dataUrl}
        width={this.props.width || "750px"}
        height={this.props.height || "1000px"}
      />
    );
  }
}

export default PDFViewer;
