import {
  ACCEPT_INVITE,
  ACCEPT_INVITE_DONE,
  ACCEPT_INVITE_ERROR,
  ACCEPT_TERMS,
  ACCEPT_TERMS_DONE,
  ACCEPT_TERMS_ERROR,
  CONFIRMATION_CODE,
  CONFIRMATION_CODE_DONE,
  CONFIRMATION_CODE_ERROR,
  FETCH_FACILITY_DETAILS,
  LOG_IN,
  LOG_IN_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT,
  SET_CREDENTIALS,
  SET_CREDENTIALS_DONE,
  SET_CREDENTIALS_ERROR,
  SET_FACILITY_DETAILS,
  UPDATE_SPACE_INFO
} from "../actions/actionTypes";

import { put, select, take } from "redux-saga/effects";
import UserService from "../../services/userService";
import { http } from "../../utils/http";
import history from "../../history";
import {
  isSessionTimeoutRequired,
  ROLE_ADMIN_MEMBER,
  ROLE_FACILITY_ADMIN,
  ROLE_MEMBER,
  ROLE_PATIENT
} from "../../security/roles";
import OrganizationsService from "../../services/organizationsService";

//
// import {
//   USER_TYPE_SPACE_ADMIN,
//   USER_TYPE_STAFF
// } from "../reducers/userReducer";export function* switchUserType() {
//   while (true) {
//     const action = yield take(SWITCH_USER_TYPE);
//     const { userType, organizationId } = action;

//     put({ type: SWITCH_USER_TYPE, userType, organizationId });
//     switch (userType) {
//       case USER_TYPE_STAFF:
//         history.push('/patients');
//         break;
//       case USER_TYPE_SPACE_ADMIN:
//         history.push('/organizations');
//         break;
//     }
//   }
// }

export function* acceptInvite() {
  while (true) {
    const action = yield take(ACCEPT_INVITE);
    // put will set loading flag
    yield put({ type: ACCEPT_INVITE });

    console.log("accept invite", action);
    try {
      const { inviteCode, email, phone, challengeFields } = action;
      const result = yield UserService.acceptInvite(
          inviteCode,
          email,
          phone,
          challengeFields
      );

      console.log("ACCEPT INVITE RESULT", result);
      yield put({
        type: ACCEPT_INVITE_DONE,
        data: result,
        inviteCode,
        email,
        phone
      });

      if (!result.credentialsPresent) {
        history.push("/setCredentials");
      } else {
        let role = result.role;
        if (
            role === ROLE_PATIENT ||
            role === ROLE_MEMBER ||
            role === ROLE_ADMIN_MEMBER
        ) {
          history.push("/credentials-present");
        } else {
          yield put({
            type: SET_CREDENTIALS_DONE,
            data: result
          });

          let facilityName = result.facilityName;
          if (facilityName) {
            facilityName = facilityName.replace(/ /g, "-");
            history.push("/login/invited/" + facilityName);
          } else {
            history.push("/login/invited");
          }
        }
      }
    } catch (error) {
      console.log("ERROR", error);
      yield put({
        type: ACCEPT_INVITE_ERROR,
        error: error.message
      });
    }
  }
}

export function* confirmationCode() {
  while (true) {
    const action = yield take(CONFIRMATION_CODE);
    // put will set loading flag
    yield put({ type: CONFIRMATION_CODE });
    console.log("accept terms", action);
    try {
      const { confirmationCode } = action;

      const onboarding = yield select(state => state.user.onboarding);
      const { email, phone, inviteCode } = onboarding;

      const result = yield UserService.confirmCode(
          confirmationCode,
          inviteCode,
          email,
          phone
      );
      const data = yield UserService.fetchSpaceDetailsFromInviteCode(
          inviteCode,
          email,
          phone
      );
      console.log("CONFIRM CODE RESULT", result);
      yield put({
        type: CONFIRMATION_CODE_DONE,
        data: data
      });
      history.push("/setCredentials");
    } catch (error) {
      console.log("ERROR", error);
      yield put({
        type: CONFIRMATION_CODE_ERROR,
        error: error.message
      });
    }
  }
}

export function* acceptTerms() {
  while (true) {
    const action = yield take(ACCEPT_TERMS);
    // put will set loading flag
    yield put({ type: ACCEPT_TERMS });

    console.log("accept terms", action);
    try {
      const user = yield select(state => state.user);
      const onboarding = user.onboarding;
      const spaceDetails = user.spaceDetails;
      const { email, phone, inviteCode } = onboarding;

      const result = yield UserService.acceptTerms(inviteCode, email, phone);
      console.log("ACCEPT TERMS RESULT", result);
      yield put({
        type: ACCEPT_TERMS_DONE
      });

      if (!spaceDetails.credentialsPresent) {
        history.push("/setCredentials");
      } else {
        yield put({
          type: SET_CREDENTIALS_DONE,
          data: result
        });
        history.push("/login/invited");
        // store.dispatch(
        //   showToaster(
        //     "Thanks for completing the on-boarding process. Please user your login credentials to access your account.",
        //     "success",
        //     10
        //   )
        // );
      }
    } catch (error) {
      console.log("ERROR", error);
      yield put({
        type: ACCEPT_TERMS_ERROR
      });
    }
  }
}

export function* setCredentials() {
  while (true) {
    const action = yield take(SET_CREDENTIALS);
    // put will set loading flag
    yield put({ type: SET_CREDENTIALS });
    console.log("accept terms", action);

    try {
      const { loginUserId, password } = action;

      const onboarding = yield select(state => state.user.onboarding);
      const { email, phone, inviteCode } = onboarding;

      const result = yield UserService.setCredentials(
          loginUserId,
          password,
          inviteCode,
          email,
          phone
      );
      console.log("SET CREDENTIALS RESULT", result);
      yield put({
        type: SET_CREDENTIALS_DONE,
        data: result
      });
      // history.push("/login");
      yield put({ type: LOG_IN, username: loginUserId, password });
    } catch (error) {
      console.log("ERROR", error);
      yield put({
        type: SET_CREDENTIALS_ERROR
      });
    }
  }
}

export function* updateSpaceDetails() {
  try {
    const userInfo = yield UserService.getSpaceInfo();
    // console.log('USER INFO', userInfo);
    yield put({ type: UPDATE_SPACE_INFO, data: userInfo });
  } catch (error) {
    console.error(error);
  }
}

export function* loginFlow() {
  while (true) {
    const action = yield take(LOG_IN);
    const { username, password, redirect } = action;
    // console.log('Sagas log in ', username, password);
    try {
      const token = yield UserService.logIn(username, password);
      // console.log('Sagas token login ', token);
      if (token) {
        const userInfo = yield UserService.getSpaceInfo();
        // console.log('USER INFO', userInfo);
        let role = userInfo.role;
        // if (role && isSessionTimeoutRequired(role)) {
        //   http.setupSessionTimeoutTrigger();
        // }
        yield put({ type: LOG_IN_SUCCESS, data: userInfo, token });
        if (redirect) {
          history.push("/");
        }
        let logoutAction = yield take(LOG_OUT);
        yield put({ type: LOG_OUT, message: logoutAction.message });
      } else {
        yield put({ type: LOG_IN_ERROR });
      }
    } catch (error) {
      // console.log('Sagas error login', error);
      yield put({ type: LOG_IN_ERROR });
    }
  }
}

export function* loadUserFacility() {
  while (true) {
    const action = yield take(FETCH_FACILITY_DETAILS);
    try {
      const result = yield OrganizationsService.loadSingle(ROLE_FACILITY_ADMIN);
      yield put({
        type: SET_FACILITY_DETAILS,
        facility: result
      });
    } catch (error) {
      // console.log('ERROR', error);
    }
  }
}
