// @flow

import {
  LOAD_CONTENT_LIBRARY,
  CONTENT_LIBRARY_LOADED,
  CONTENT_LIBRARY_RESET_LOADING,
  CONTENT_ADD_DONE,
  CONTENT_ADD_ERROR,
  CONTENT_LIBRARY_TOPICS_LOADED,
  LOAD_CONTENT_LIBRARY_TOPICS
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  list: [],
  topics: [],
  topicsMap: {},
  loadMore: false,
  totalItems: 0,
  page: 0,
  // error info
  error: false,
  errorMsg: ""
};

const contentLibraryReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case CONTENT_LIBRARY_RESET_LOADING:
    case CONTENT_ADD_DONE:
      return { ...initialState, contentAdded: action.data };

    case CONTENT_ADD_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case LOAD_CONTENT_LIBRARY:
    case LOAD_CONTENT_LIBRARY_TOPICS:
      return { ...state, loading: true };

    case CONTENT_LIBRARY_LOADED:
      // console.log('statuses loaded', action);
      newState = {
        // ...state,
        // loading: false,
        // loadMore: false,
        // list: [].concat(action.list),

        ...state,
        loading: false,
        page: action.page,
        loadMore: action.list.length > 0,
        list: [].concat(action.list),
        totalItems: action.totalItems
      };
      // console.log('REDUCER STATUSES ', newState);
      return newState;

    case CONTENT_LIBRARY_TOPICS_LOADED:
      // console.log('statuses loaded', action);

      let topicsMap = {};
      action.topics.forEach(topic => {
        topicsMap[topic.topicId] = topic;
      });
      return Object.assign({}, state, {
        topics: action.topics,
        topicsMap: topicsMap,
        loading: false
      });

    default:
      return state;
  }
};

export default contentLibraryReducer;
