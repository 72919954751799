import {
  STORE_INFO_PANEL,
  REMOVE_INFO_PANEL,
  LOG_OUT
} from "../actions/actionTypes";

import { REHYDRATE } from "redux-persist";

export const emptyInfoPanel = {
  details: {},
  sections: []
};

const initialState = {
  infoPanel: emptyInfoPanel
};

const infoPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_INFO_PANEL:
      let infoPanel = state.infoPanel;

      return { ...state, infoPanel: { ...infoPanel, ...action.infoPanel } };

    case REMOVE_INFO_PANEL:
      return { infoPanel: emptyInfoPanel };

    case REHYDRATE:
      if (action.payload && action.payload.infoPanel) {
        return action.payload.infoPanel;
      }
      return state;

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default infoPanelReducer;
