import React, { PureComponent } from "react";
import userService from "../../services/userService";
import UserOnboardingView from "./UserOnboardingView";

class UserOnboardingContainer extends PureComponent {
  constructor(props) {
    super(props);
    if (this.props.facilityInfo) {
      this.state = this.getStateFromFacilityInfo(this.props.facilityInfo);
    } else {
      this.state = {
        step: "invite",
        challengeFields: []
      };
    }
  }

  componentDidMount() {
    this.props.onLogout();
    this.props.onSetOnboardingUserName("");
    let { inviteCode, onboardingType, linkType } = this.props;
    if (this.props.facilityInfo) {
      return;
    }

    this.setState({ challengeLoading: true, confirmSuccessMessage: null });

    let api = null;
    if (onboardingType === "confirm") {
      api = userService.confirmEmail;
    } else if (onboardingType === "signup") {
      api = userService.fetchChallengeFieldsFromFacilityCode;
    } else {
      api = userService.fetchChallengeFields;
    }

    api(inviteCode, linkType)
      .then(facilityInfo => {
        if (!facilityInfo) {
          this.setState({ challengeLoading: false });
          return;
        }
        this.setState(
          this.getStateFromFacilityInfo(facilityInfo, onboardingType)
        );
      })
      .catch(e => {
        this.setState({
          challengeLoading: false,
          inviteError: true,
          inviteErrMessage: e.message,
          errorCode: e.errorCode
        });
      });
  }

  getStateFromFacilityInfo = (facilityInfo, onboardingType) => {
    let { credentialsPresent, challengeFields = [], email } = facilityInfo;
    let step = "invite";
    if (onboardingType === "confirm") {
      step = "show-confirmation";
      return {
        step: step,
        challengeLoading: false,
        confirmSuccessMessage: facilityInfo.confirmSuccessMessage
      };
    } else {
      if (credentialsPresent) {
        step = challengeFields.length === 0 ? "terms" : "invite-terms";
      } else {
        step = challengeFields.length === 0 ? "set-credentials" : "invite";
      }
      return {
        step: step,
        challengeLoading: false,
        facilityInfo,
        challengeFields: challengeFields,
        email: email
      };
    }
  };

  confirmIdentity = challengeFields => {
    this.setState({ error: false, errorMsg: "" });
    let step = this.state.step;
    let acceptedTermsAndCondition = step === "terms" || step === "invite-terms";
    userService
      .onboardingConfirmIdentity(
        this.props.inviteCode,
        challengeFields,
        acceptedTermsAndCondition,
        this.props.onboardingType
      )
      .then(response => {
        if (step === "terms" || step === "invite-terms") {
          this.setState({ step: "credentials-present-message" });
        } else {
          if (response.credentialsPresent) {
            this.setState({ step: "credentials-present-message" });
          } else {
            this.setState({
              step: "set-credentials",
              patientId: response.patientId,
              email: response.email
            });
          }
        }
      })
      .catch(e => {
        let message = e.message;
        if (e.errorCode === "mps.challenge.failed") {
          message = "Verification Failed";
        }
        this.setState({ error: true, errorMsg: message });
      });
  };

  setCredentials = (username, password) => {
    userService
      .onboardingSetCredentials(
        this.props.inviteCode,
        username,
        password,
        true,
        this.props.onboardingType,
        this.state.patientId
      )
      .then(response => {
        this.props.onSetOnboardingUserName(username);
        this.props.onLogin(username, password, true);
      });
  };

  render() {
    let {
      challengeLoading,
      facilityInfo,
      step,
      challengeFields = [],
      error,
      errorMsg,
      inviteError,
      inviteErrMessage,
      errorCode,
      email,
      confirmSuccessMessage
    } = this.state;

    let { onboardingType, linkType, selfSignup } = this.props;

    return (
      <UserOnboardingView
        onboardingType={onboardingType}
        loading={challengeLoading}
        error={error}
        errorMsg={errorMsg}
        step={step}
        facilityInfo={facilityInfo}
        challengeFields={challengeFields}
        confirmIdentity={this.confirmIdentity}
        setCredentials={this.setCredentials}
        inviteError={inviteError}
        inviteErrMessage={inviteErrMessage}
        errorCode={errorCode}
        history={this.props.history}
        email={email}
        linkType={linkType}
        selfSignup={selfSignup}
        confirmSuccessMessage={confirmSuccessMessage}
      />
    );
  }
}

export default UserOnboardingContainer;
