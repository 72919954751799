import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";

class IntegerAttribute extends Component {
  static getDisplayValue(av) {
    return av.valueInteger ? av.valueInteger : null;
  }

  render() {
    let { attributeValue, setData } = this.props;

    let value = attributeValue.valueInteger || null;
    return (
      <FormGroup>
        <div className="inputHolder flex-row">
          <Input
            value={value}
            onChange={e => {
              attributeValue.valueInteger = e.target.value;
              setData(attributeValue);
            }}
            type="number"
          />
        </div>
      </FormGroup>
    );
  }
}

export default IntegerAttribute;
