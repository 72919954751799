import React, { PureComponent } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import OnboardingChallengeInvite from "./OnboardingChallengeInvite";
import StepInviteEmailPhone from "./StepInviteEmailPhone";
import StepAcceptTerms from "./StepAcceptTerms";

class StepInvite extends PureComponent {
  componentDidMount() {
    this.props.onLogout();
  }

  getTitleMessage = () => {
    let { linkType, challenge } = this.props;
    if (challenge) {
      return "Enter the following details to accept invitation.";
    }
    if (!linkType) {
      return "Enter your email or phone with country code to accept invitation.";
    }
    if (linkType && linkType === "email") {
      return "Enter your email to accept invitation.";
    }

    if (linkType && linkType === "phone") {
      return "Select country and enter your phone number to accept invitation.";
    }
  };

  render() {
    let {
      inputType,
      email,
      countryCode,
      phone,
      onSubmit,
      error,
      errorMsg,
      errorEmail,
      errorPhone,
      loading,
      linkType,
      challenge,
      challengeFields,
      challengeLoading,
      errorTerms
    } = this.props;

    return (
      <Form onSubmit={onSubmit} noValidate>
        <h3 className="text-center mb-3 font-sm-size">
          Step 1 of 3: Accept Invitation
        </h3>
        <p className="text-muted">
          {"You’ve been invited to join myPatientSpace. " +
            this.getTitleMessage()}
        </p>

        {error && (
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>
              {errorMsg ||
                (inputType === "EMAIL" ? "email" : "phone") + " are wrong."}
            </span>
          </p>
        )}

        {errorTerms && (
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>
              Please accept privacy policy and terms and conditions.
            </span>
          </p>
        )}

        {challenge && (
          <OnboardingChallengeInvite
            onUpdateData={this.props.onUpdateData}
            challengeFields={challengeFields}
            challengeLoading={challengeLoading}
          />
        )}

        {!challenge && (
          <StepInviteEmailPhone
            onUpdateData={this.props.onUpdateData}
            inputType={inputType}
            email={email}
            countryCode={countryCode}
            phone={phone}
            errorEmail={errorEmail}
            errorPhone={errorPhone}
            loading={loading}
            linkType={linkType}
          />
        )}

        <StepAcceptTerms
          onUpdateData={this.props.onUpdateData}
          facilityInfo={this.props.facilityInfo}
          loading={loading}
          termsAccepted={this.props.termsAccepted}
        />

        {!challengeLoading && (
          <Row>
            <Col xs="12">
              <div className="text-right">
                <Button
                  size="lg"
                  color="primary"
                  className="px-4"
                  disabled={loading}
                >
                  Accept Invite
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}

export default StepInvite;
