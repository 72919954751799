import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup
} from "reactstrap";
import React from "react";
import classnames from "classnames";

class StepSetCredentials extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { useEmail: false };
  }

  onUseEmailClicked = () => {
    let old = this.state.useEmail;
    this.setState({ useEmail: !old });
    this.props.onUpdateData("username", !old ? this.props.email : "");
  };

  onUpdateUsername = e => {
    this.props.onUpdateData("username", e.target.value);
  };
  onUpdatePassword = e => {
    this.props.onUpdateData("password", e.target.value);
  };
  onUpdateRepeatPassword = e => {
    this.props.onUpdateData("repeatPassword", e.target.value);
  };

  render() {
    let {
      username,
      password,
      repeatPassword,
      onSubmit,
      error,
      errorUsername,
      errorPassword,
      errorRepeatPassword,
      loading
    } = this.props;

    return (
      <Form onSubmit={onSubmit}>
        <h3 className="text-center mb-3 font-sm-size">
          Step 2 of 3: Your Credentials
        </h3>
        <p className="text-muted">
          Set username and password for your account.
        </p>

        {error && (
          <p className="text-muted text-center">
            <span style={{ color: "red" }}>
              Error with username and password set.
            </span>
          </p>
        )}

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            User Name{" "}
            <div>
              <small>Only letters, numbers and underline are allowed</small>
            </div>
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(
                  error || errorUsername ? "is-invalid" : ""
                )}
              >
                <i className="fa fa-user" />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              className={classnames(error || errorUsername ? "is-invalid" : "")}
              value={username}
              onChange={this.onUpdateUsername}
              type="text"
              autoCapitalize="none"
              disabled={loading}
            />
            <div className="invalid-feedback">Please set username.</div>
          </InputGroup>
          {this.props.email && (
            <div className="my-2 d-flex">
              <div>
                <Input
                  type="checkbox"
                  className="ml-0 mr-3"
                  style={{ position: "relative", marginTop: "0px" }}
                  checked={this.state.useEmail}
                  onChange={this.onUseEmailClicked}
                  disabled={loading}
                />
              </div>
              Please use my email as username.
            </div>
          )}
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            Password{" "}
            <div>
              <small>
                Minimum 8 characters with 1 lowercase & 1 uppercase letter, 1
                number, and no whitespaces.
              </small>
            </div>
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(
                  error || errorPassword ? "is-invalid" : ""
                )}
              >
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={password}
              className={classnames(error || errorPassword ? "is-invalid" : "")}
              onChange={this.onUpdatePassword}
              type="password"
              disabled={loading}
              autoCapitalize="none"
            />
            <div className="invalid-feedback">Please set valid password.</div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">Confirm Password</div>

          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(
                  error || errorPassword ? "is-invalid" : ""
                )}
              >
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={repeatPassword}
              className={classnames(
                error || errorRepeatPassword ? "is-invalid" : ""
              )}
              onChange={this.onUpdateRepeatPassword}
              type="password"
              disabled={loading}
              autoCapitalize="none"
            />
            <div className="invalid-feedback">
              Repeated password must be same as password.
            </div>
          </InputGroup>
        </FormGroup>

        <Row>
          <Col xs="12">
            <div className="text-right">
              <Button
                color="primary"
                size="lg"
                className="px-4"
                disabled={loading}
              >
                Create Account
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default StepSetCredentials;
