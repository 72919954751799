import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

class CredentialsPresentMessage extends PureComponent {
  render() {
    let { facilityName } = this.props;

    return (
      <div style={{ color: "#28623c" }} className="mb-4">
        {`Welcome back! You have just joined ${facilityName}. 
        You already have an account with myPatientSpace. Please download and login on the app.`}
        <br />
        <br />
        If you don't remember the username or password, click
        <Link type="link" className="mx-1" to="/forgot-password">
          here
        </Link>
        to reset.
      </div>
    );
  }
}

export default CredentialsPresentMessage;
