import { http } from "../utils/http";
import { API_ENDPOINTS, PAGE_SIZE } from "../config";
import { ROLE_PATIENT } from "../security/roles";
import { store } from "../State/store/configureStore";
import StorageService from "./storageService";

export const RECENT_PATIENTS = "RECENT_PATIENTS";

class PatientsService {
  inviteAdminUser(patientId, data) {
    return http.request(
      API_ENDPOINTS.inviteAdminUser(
        patientId,
        data.hasOwnProperty("loginUserId")
      ),
      "post",
      data
    );
  }

  inviteSpaceMembers(patientId, data) {
    return http.request(
      API_ENDPOINTS.inviteSpaceMember(
        patientId,
        data.hasOwnProperty("loginUserId")
      ),
      "post",
      data
    );
  }

  getSpaceDetails(inviteCode, email) {
    return http.request(API_ENDPOINTS.getSpaceDetails(), "post", {
      inviteCode,
      email
    });
  }

  add(data, internal) {
    let api = null;
    if (internal) {
      api =
        API_ENDPOINTS.addPatientWithoutInvite() + "?makeActiveInternal=true";
    } else {
      api = API_ENDPOINTS.addPatient(data.hasOwnProperty("loginUserId"));
    }

    return http.request(api, "post", data);
  }

  delete(patientId, rip) {
    let api = API_ENDPOINTS.deletePatient(patientId);
    if (rip) {
      api = api + `?isRIP=true`;
    }
    return http.request(api, "delete");
  }

  update(data) {
    return http.request(
      API_ENDPOINTS.update_patient + data.patientId,
      "patch",
      data
    );
  }

  loadSingle(patientId) {
    return http.request(API_ENDPOINTS.loadPatient(patientId));
  }

  loadMembers(patientId, page) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientMembers + "?page=0&size=1000";
    } else {
      api = API_ENDPOINTS.loadMembers(patientId) + "?page=0&size=1000";
    }

    return http.request(api).then(data => {
      if (data && data._embedded) {
        return {
          data: data._embedded.memberResources || [],
          loadMore: false
        };
      } else {
        return {
          data: [],
          loadMore: false
        };
      }
    });
  }

  loadPatientsWithBasicDetails(searchTerm, pageNumber) {
    let api =
      API_ENDPOINTS.patientsWithBasicDetails + "?size=20&page=" + pageNumber;
    if (searchTerm) {
      api += "&q=" + searchTerm;
    }

    return http.request(api).then(response => {
      if (response && response._embedded) {
        return {
          patients: response._embedded.patients || [],
          pageInfo: response.page
        };
      }
      return {
        patients: [],
        pageInfo: { number: -1 }
      };
    });
  }

  load(page, criteria = {}, all) {
    let pageSize = all ? 1000 : 10;
    let endPoint =
      API_ENDPOINTS.loadPatients() + `?page=${page}&size=${pageSize}`;

    let uiFields = criteria ? criteria.uiFields : {};
    let uiFieldsArr = [];
    if (uiFields && uiFields.length > 0) {
      uiFields.forEach(attribute => {
        let operatorsSupported = attribute.operatorsSupported || [];
        uiFieldsArr.push({
          dataField: attribute.dataField,
          value: attribute.value,
          operator: attribute.operator || "EQUALS"
          // (operatorsSupported[0] ? operatorsSupported[0] : "EQUALS")
        });
      });
    }

    let formData = {
      mode: criteria ? criteria.mode : null
    };

    if (uiFieldsArr.length > 0) {
      formData.uiFields = uiFieldsArr;
    }

    if (criteria.searchTerm) {
      formData.searchTerm = criteria.searchTerm;
    }

    if (criteria && criteria.sortField) {
      formData.sortField = { dataField: criteria.sortField };
      formData.sortDirection = criteria.sortDirection;
    }

    if (criteria.recentPatientIds) {
      formData.recentPatientIds = criteria.recentPatientIds;
    }

    if (criteria.statField) {
      formData.statField = criteria.statField;
      formData.stat = criteria.stat;
    }

    return http.request(endPoint, "post", formData).then(data => {
      if (data && data._embedded) {
        return {
          data: data._embedded.getAllPatientResources || [],
          loadMore: data.page.totalPages > page + 1,
          total: data.page.totalElements
        };
      } else {
        return {
          data: [],
          loadMore: false
        };
      }
    });
  }

  loadMyPatients(page, criteria) {
    let endPoint =
      API_ENDPOINTS.loadMyPatients + `?page=${page}&size=${PAGE_SIZE}`;
    if (criteria) {
      if (criteria.name) {
        endPoint += "&q=" + criteria.name;
      }
    }

    return http.request(endPoint).then(data => {
      if (data && data._embedded) {
        return {
          data: data._embedded.patients || [],
          loadMore: data.page.totalPages > page + 1,
          total: data.page.totalElements
        };
      } else {
        return {
          data: [],
          loadMore: false
        };
      }
    });
  }

  loadArtifact(artifactLink) {
    return http.request(artifactLink);
  }

  loadPatientProfile(patientId) {
    return http.request(API_ENDPOINTS.getPatientProfile(patientId));
  }

  addNewDocument(patientId, document) {
    return http.request(API_ENDPOINTS.addDocument(patientId), "post", document);
  }

  loadPatientDocuments(patientId, pageNumber, pageSize = 20) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientDocuments + "&page=0&size=500";
    } else {
      api =
        API_ENDPOINTS.loadPatientDocuments(patientId) +
        `&page=${pageNumber}&size=${pageSize}`;
    }

    return http.request(api).then(data => {
      // if (role === ROLE_PATIENT) {
      //   return data && data._embedded ? data._embedded.patientDocuments : [];
      // } else {
      return {
        documents:
          data && data._embedded && data._embedded.patientDocuments
            ? data._embedded.patientDocuments
            : [],
        pageInfo: data.page ? data.page : { number: 0, totalPages: 0 }
      };
      // }
    });
  }

  loadPatientDevices(patientId) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientDevices;
    } else {
      api = API_ENDPOINTS.loadPatientDevices(patientId);
    }

    return http.request(api).then(data => {
      return data && data._embedded ? data._embedded.provisionedDevices : [];
    });
  }

  updateProfileAttribute(patientId, formData) {
    return http.request(
      API_ENDPOINTS.updatePatientProfileAttribute(patientId),
      "post",
      formData
    );
  }

  addMember(patientId, data, admin) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientMembers;
    } else {
      api = API_ENDPOINTS.addMember(
        data.hasOwnProperty("loginUserId"),
        patientId
      );
    }

    return http.request(api, "post", data);
  }

  removeMember(patientId, memberId) {
    let role = store.getState().user.role;
    let api = null;
    if (role === ROLE_PATIENT) {
      api = API_ENDPOINTS.patientMembers + `/${memberId}`;
    } else {
      api = API_ENDPOINTS.removeMember(patientId, memberId);
    }
    return http.request(api, "delete");
  }

  editMember(patientId, memberId, data) {
    return http.request(
      API_ENDPOINTS.editMember(patientId, memberId),
      "patch",
      data
    );
  }

  reinvitePatient(patientId, data) {
    return http.request(API_ENDPOINTS.reInvitePatient(patientId), "post", data);
  }

  tagPatient(patientId) {
    return http.request(API_ENDPOINTS.tagPatient(patientId), "post");
  }

  untagPatient(patientId) {
    return http.request(API_ENDPOINTS.tagPatient(patientId), "delete");
  }

  restorePatient(patientId) {
    return http.request(API_ENDPOINTS.restorePatient(patientId), "post");
  }

  addToRecent(patientId) {
    let recents = StorageService.getFromLocalStorage(RECENT_PATIENTS) || [];
    let newRecents = [];

    for (let i = 0; i < recents.length; i++) {
      if (recents[i] !== patientId) {
        newRecents.push(recents[i]);
      }
    }
    newRecents.unshift(patientId);
    StorageService.addToLocalStorage(RECENT_PATIENTS, newRecents.slice(0, 20));
  }

  resetCredentials(patientId, data) {
    return http.request(
      API_ENDPOINTS.resetPatientCredentials(patientId),
      "post",
      data
    );
  }

  deleteDocument(patientId, docId) {
    return http.request(API_ENDPOINTS.documentById(patientId, docId), "delete");
  }

  loadPatientsForMPSAdmin(searchKey, page = 0) {
    return http
      .request(
        API_ENDPOINTS.MPSAdminPatients(searchKey) + "&size=20&page=" + page
      )
      .then(data => {
        if (data) {
          return {
            patients: data._embedded ? data._embedded.patients || [] : [],
            pageInfo: data.page
          };
        } else {
          return {
            patients: [],
            pageInfo: { number: -1 }
          };
        }
      });
  }
}

const patientsService = new PatientsService();

export default patientsService;
