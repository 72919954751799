import React, { PureComponent } from "react";
import { Button, Col, Row } from "reactstrap";
import StepAcceptTerms from "../Onboarding/StepAcceptTerms";

class SignupConsent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  onNext = () => {
    if (!this.state.termsAccepted) {
      this.setState({ errorTerms: true });
    } else {
      this.setState({ errorTerms: false });
      this.props.incrementStep();
    }
  };

  render() {
    let { facilityInfo } = this.props;
    let { termsAccepted, errorTerms } = this.state;
    let { consentText } = facilityInfo;
    return (
      <div>
        <Row>
          <Col xs="12">
            <div className="my-3">
              <div dangerouslySetInnerHTML={{ __html: consentText }} />
            </div>

            <div>
              <StepAcceptTerms
                onUpdateData={this.onUpdateTermsAccepted}
                facilityInfo={this.props.facilityInfo}
                termsAccepted={termsAccepted}
              />
              {errorTerms && (
                <p className="text-muted">
                  <span style={{ color: "red" }}>
                    Please accept privacy policy and terms and conditions.
                  </span>
                </p>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <div>
                <Button
                  color="secondary"
                  className="px-4"
                  block
                  onClick={this.props.decrementStep}
                >
                  Back
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  className="px-4"
                  block
                  onClick={this.onNext}
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupConsent;
