import { fork } from "redux-saga/effects";

import {
  acceptInvite,
  acceptTerms,
  confirmationCode,
  loadUserFacility,
  loginFlow,
  setCredentials
} from "./userSagas";
import {
  addOrganization,
  loadOrganization,
  loadOrganizations,
  updateOrganization,
  editOrganization,
  updateOrganizationBranding
} from "./organizationsSagas";
import {
  addPatient,
  getSpaceDetails,
  inviteAdminUser,
  inviteSpaceMembers,
  loadMembers,
  loadPatient,
  loadPatients,
  updatePatient,
  deletePatient
} from "./patientsSagas";
import {
  loadStatuses,
  loadStatusTemplates,
  addStatusTemplate
} from "./statusesSagas";
import { addSurvey, loadSurveys } from "./surveysSagas";
import {
  deleteAdmin,
  inviteAdmin,
  loadAdmins
} from "./organizationAdminsSagas";
import { deleteStaff, inviteStaff, loadStaff } from "./staffSagas";

import { loadPatientTypes } from "./patientTypesSagas";

import {
  loadLibrary,
  addLibraryContent,
  editLibraryContent,
  loadLibraryTopics,
  addTopic
} from "./contentLibrarySagas";

import {
  editMasterData,
  addNewMasterData,
  masterDataOnLoad,
  deleteMasterData
} from "./masterDataSagas";

export default function* root() {
  yield fork(loginFlow);
  yield fork(acceptInvite);
  yield fork(acceptTerms);
  yield fork(confirmationCode);
  yield fork(setCredentials);

  yield fork(loadOrganization);
  yield fork(loadOrganizations);
  yield fork(updateOrganization);
  yield fork(editOrganization);
  yield fork(updateOrganizationBranding);
  yield fork(addOrganization);

  yield fork(loadAdmins);
  yield fork(inviteAdmin);
  yield fork(deleteAdmin);

  yield fork(loadStaff);
  yield fork(inviteStaff);
  yield fork(deleteStaff);

  yield fork(loadPatient);
  yield fork(loadPatients);
  yield fork(loadMembers);
  yield fork(updatePatient);
  yield fork(addPatient);
  yield fork(deletePatient);
  yield fork(inviteAdminUser);
  yield fork(inviteSpaceMembers);
  yield fork(getSpaceDetails);

  yield fork(loadPatientTypes);

  yield fork(loadStatuses);
  yield fork(addStatusTemplate);
  yield fork(loadStatusTemplates);

  yield fork(loadSurveys);
  yield fork(addSurvey);

  yield fork(loadLibrary);
  yield fork(addLibraryContent);
  yield fork(editLibraryContent);
  yield fork(loadLibraryTopics);
  yield fork(addTopic);

  yield fork(editMasterData);
  yield fork(addNewMasterData);
  yield fork(masterDataOnLoad);
  yield fork(deleteMasterData);

  yield fork(loadUserFacility);
}
