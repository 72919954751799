import React, { PureComponent } from "react";
import {
  Button,
  ButtonGroup,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import CountryCodes from "../../Shared/FHIR/CountryCodes";

class StepInviteEmailPhone extends PureComponent {
  onUpdateInputType = value => {
    this.props.onUpdateData("inputType", value);
  };

  onUpdateEmail = e => {
    this.props.onUpdateData("email", e.target.value);
  };

  onUpdateCountryCode = cntrObj => {
    if (cntrObj) {
      this.props.onUpdateData("countryCode", cntrObj.value);
    } else {
      this.props.onUpdateData("countryCode", null);
    }
  };

  onUpdatePhone = e => {
    this.props.onUpdateData("phone", e.target.value);
  };

  render() {
    let {
      inputType,
      email,
      countryCode,
      phone,
      errorEmail,
      errorPhone,
      loading,
      linkType
    } = this.props;

    return (
      <div>
        {!linkType && (
          <FormGroup className="d-flex align-items-center">
            <Label style={{ width: "auto", marginRight: "10px" }}>
              Received As
            </Label>
            <ButtonGroup>
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.onUpdateInputType("EMAIL");
                }}
                className={classnames(
                  inputType === "EMAIL" ? "btn-sm active" : "btn-sm"
                )}
              >
                Email
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.onUpdateInputType("PHONE");
                }}
                className={classnames(
                  inputType === "PHONE" ? "btn-sm active" : "btn-sm"
                )}
              >
                Phone
              </Button>
            </ButtonGroup>
          </FormGroup>
        )}

        {inputType === "EMAIL" && (
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(errorEmail ? "is-invalid" : "")}
              >
                <i className="fa fa-envelope-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className={classnames(errorEmail ? "is-invalid" : "")}
              value={email}
              onChange={this.onUpdateEmail}
              type="email"
              placeholder="E-mail"
              disabled={loading}
            />{" "}
            <div className="invalid-feedback">Please set valid email.</div>
          </InputGroup>
        )}

        {inputType === "PHONE" && (
          <InputGroup className="mb-3 d-flex">
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(errorPhone ? "is-invalid" : "")}
              >
                <i className="fa fa-phone" />
              </InputGroupText>
            </InputGroupAddon>
            <Select
              style={{
                border: errorPhone ? "1px solid #f86c6b" : "1px solid #e0e6e8",
                borderRadius: 0
              }}
              value={countryCode}
              onChange={this.onUpdateCountryCode}
              placeholder="Country code"
              options={CountryCodes}
              labelKey="country"
              valueKey="value"
              disabled={loading}
            />
            <Input
              value={phone}
              onChange={this.onUpdatePhone}
              disabled={loading}
              className={classnames(errorPhone ? "is-invalid ml-1" : "ml-1")}
              placeholder="Phone Number"
              autoComplete="nope"
            />
            <div className="invalid-feedback">Please set valid phone.</div>
          </InputGroup>
        )}
      </div>
    );
  }
}

export default StepInviteEmailPhone;
